import { QUERY_KEY } from 'interfaces/constants';
import { sendGetCurrentUserRequest } from 'network/services/auth';
import {
  sendGetAllCurrencies,
  sendGetGeneralSettings,
} from 'network/services/user';
import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { isValidToken } from 'utils/auth';

const useGeneralSettings = () => {
  const isAuth = isValidToken();
  const { data, isLoading, refetch } = useQuery(
    [QUERY_KEY.GET_GENERAL_SETTING],
    () => sendGetGeneralSettings(),
    {
      keepPreviousData: false,
      retry: false,
      enabled: isAuth,
      cacheTime: 2,
    }
  );
  const generalSettings = useMemo(() => {
    return data;
  }, [data]);

  return { generalSettings, isLoading, refetch };
};

export default useGeneralSettings;
