import React from 'react';
import Svg from '../../Svg';
import { SvgProps } from '../../types';

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 220 220" {...props}>
      <g clip-path="url(#clip0_108_398)">
        <path
          d="M110 220C170.751 220 220 170.751 220 110C220 49.2487 170.751 0 110 0C49.2487 0 0 49.2487 0 110C0 170.751 49.2487 220 110 220Z"
          fill="#EF0027"
        />
        <path
          d="M150.782 68.1511L51.5625 49.8911L103.778 181.286L176.536 92.6399L150.782 68.1511ZM149.188 76.1949L164.368 90.6255L122.856 98.1399L149.188 76.1949ZM113.836 96.6342L70.0837 60.348L141.597 73.5067L113.836 96.6342ZM110.722 103.055L103.586 162.043L65.12 65.2224L110.722 103.049V103.055ZM117.322 106.184L163.295 97.8649L110.564 162.098L117.322 106.184Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_108_398">
          <rect width="220" height="220" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
