import React, { useMemo, useState } from 'react';
import CardInfoItem from './CardInfoItem';
import { nFormatter } from 'utils/number-format';
import ReactECharts from 'echarts-for-react';
import PieChartAnalytics from './PieChartAnalytics';
import { isThisMonth, isThisWeek, isToday, parseISO } from 'date-fns';
import useHistory from 'hooks/history.hook';
import Coin3D from 'assets/img/decorations/3d-coin.png';
import Icon2 from 'assets/img/decorations/pe2.png';
import Icon3 from 'assets/img/decorations/world.png';
import Icon4 from 'assets/img/decorations/box.png';
import Icon5 from 'assets/img/decorations/tc.png';
import Icon6 from 'assets/img/decorations/wallet.png';

export const OverView = ({ userList, dataUser, generalSettings }: any) => {
  const [filterDate, setFilterDate] = useState('ALL');
  const { historyData } = useHistory();
  console.log('h', historyData);

  const dataFilter = useMemo(() => {
    switch (filterDate) {
      case 'D':
        return dataUser?.usersCountByDate?.filter((item) =>
          isToday(parseISO(item._id))
        );
      case 'W':
        return dataUser?.usersCountByDate.filter((item) =>
          isThisWeek(parseISO(item._id))
        );
      case 'M':
        return dataUser?.usersCountByDate.filter((item) =>
          isThisMonth(parseISO(item._id))
        );
      case 'ALL':
        return dataUser?.usersCountByDate;

      default:
        return dataUser?.usersCountByDate;
    }
  }, [dataUser?.usersCountByDate, filterDate]);

  const options = {
    radius: '100%',
    grid: {
      left: '30px',
      right: '10px',
      top: '10px',
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: dataFilter?.map((item) => item._id),
    },
    tooltip: {
      trigger: 'axis', // Hiển thị tooltip khi hover vào các điểm trên trục x
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: dataFilter?.map((item) => item.count),
        type: 'line',
        smooth: true,

        // symbol: 'none',
        areaStyle: {
          color: '#03c4fc',
          opacity: 0.25,
        },
        itemStyle: {
          color: '#03c4fc', // Customize the line color (example: blue)
        },
        lineStyle: {
          width: 4,
        },
      },
    ],
  };
  return (
    <div>
      <div className=" bg-white border border-gray-200 rounded-xl shadow dark:bg-gray-800 dark:border-gray-700 h-full">
        <div className="pt-[20px]">
          <div className="flex items-center gap-4 justify-between pr-8">
            <div className="font-bold pl-[40px]">
              <div className="text-[20px]">Total portfolio users</div>
              <div className="text-[24px] !text-base7">
                {userList?.totalUser} users
              </div>
            </div>
            <div className="flex items-center gap-4">
              <span className="text-sm">Time range:</span>
              <div className="text-[14px] flex items-center gap-3">
                <div
                  onClick={() => setFilterDate('D')}
                  className={
                    `border rounded-lg w-[40px] h-[32px] leading-8 text-center text-default cursor-pointer` +
                    `${filterDate === 'D' ? ' !bg-[#6764FF] !text-white' : ''}`
                  }
                >
                  1D
                </div>
                <div
                  onClick={() => setFilterDate('M')}
                  className={
                    `border rounded-lg w-[40px] h-[32px] leading-8 text-center cursor-pointer` +
                    `${filterDate === 'M' ? ' !bg-[#6764FF] !text-white' : ''}`
                  }
                >
                  1W
                </div>
                <div
                  onClick={() => setFilterDate('Y')}
                  className={
                    `border rounded-lg w-[40px] h-[32px] leading-8 text-center cursor-pointer` +
                    `${filterDate === 'Y' ? ' !bg-[#6764FF] !text-white' : ''}`
                  }
                >
                  1M
                </div>
                <div
                  onClick={() => setFilterDate('ALL')}
                  className={
                    `border rounded-lg w-[40px] h-[32px] leading-8 text-center cursor-pointer` +
                    `${
                      filterDate === 'ALL' ? ' !bg-[#6764FF] !text-white' : ''
                    }`
                  }
                >
                  All
                </div>
              </div>
            </div>
          </div>
          <div className="w-full px-[16px] pt-[20px]">
            <ReactECharts option={options} style={{ height: 350 }} />
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-6 gap-y-3 py-4">
        <div className="lg:col-span-8 col-span-12">
          <div className="grid lg:grid-cols-9 gap-5 items-center h-full">
            <CardInfoItem
              image={Coin3D}
              // customClass="bg-[#ffe8ef]"
              title="TOKENS"
              amount={nFormatter(dataUser?.totalBalance?.toString())}
              total={nFormatter(
                (
                  dataUser?.totalBalance * generalSettings?.token_rate
                )?.toString()
              )}
            />
            <CardInfoItem
              image={Icon2}
              // customClass="bg-[#dad7f4]"
              title="FAUCET"
              amount={nFormatter(dataUser?.totalFaucetToday?.toString())}
              total={nFormatter(
                (
                  dataUser?.totalFaucetToday *
                  generalSettings?.token_rate *
                  generalSettings?.reward
                )?.toString()
              )}
            />
            <CardInfoItem
              image={Icon3}
              // customClass="bg-[#fff3d4]"
              title="SHORTURL"
              amount={nFormatter(dataUser?.totalShortLinksToday?.toString())}
              link="/admin/short-link-top"
            />

            <CardInfoItem
              image={Icon4}
              // customClass="bg-[#dad7f4]"
              title="OFFERWALL"
              amount={nFormatter(dataUser?.countPendingOfferwall?.toString())}
              total={nFormatter(dataUser?.amountOfferwallPending?.toString())}
              prefix=""
              surfix="tokens"
            />
            <CardInfoItem
              image={Icon5}
              // customClass="bg-[#fff3d4]"
              title="CAMPAIGN"
              amount={nFormatter(dataUser?.campains?.toString())}
            />
            <CardInfoItem
              image={Icon6}
              // customClass="bg-[#ffe8ef]"
              title="Payment"
              amount={nFormatter(dataUser?.countPendingWithdraw?.toString())}
              total={nFormatter(dataUser?.amountPendingWithdraw?.toString())}
              prefix=""
              surfix="tokens"
            />
          </div>
        </div>
        <div className="lg:col-span-4 col-span-12">
          <div className=" p-3 bg-white border border-gray-200 rounded-xl shadow dark:bg-gray-800 dark:border-gray-700 h-full">
            <div className="p-3">
              <p className="font-bold text-textMenu text-[32px]">
                <PieChartAnalytics historyData={userList} />
                {/* <img src={GameNft} alt="Yay!" className="w-[110px]" /> */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
