import { QUERY_KEY } from 'interfaces/constants';
import {
  sendGetCapcha,
  sendGetCurrentUserRequest,
} from 'network/services/auth';
import { sendGetAllCurrencies } from 'network/services/user';
import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { isValidToken } from 'utils/auth';

const useCapcha = () => {
  const {
    data,
    isLoading,
    refetch,
    isSuccess,
    isFetching,
    isFetchedAfterMount,
  } = useQuery([QUERY_KEY.GET_CAPCHA], () => sendGetCapcha(), {
    keepPreviousData: false,
    retry: false,
    cacheTime: 2,
  });
  const capcha = useMemo(() => {
    // return data?.blob();
    return data;
  }, [data]);

  return { capcha, isLoading, refetch, isFetching };
};

export default useCapcha;
