import * as React from 'react';
import cx from 'classnames';
import { BaseControlProps } from 'interfaces';

export const ErrorText = ({ className, children }: BaseControlProps) => {
  return (
    <label
      className={cx('block text-xs text-red-500 mb-0 pb-[8px]', className)}
    >
      {children}
    </label>
  );
};
