import React, { useEffect } from 'react';
import './index.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { withLoading } from 'components/hocs/withLoading.hoc';
import MainLayout from 'components/layout/admin/MainLayout';
import UserLayout from 'components/layout/users/UserLayout';
import LoginPage from './pages/login';
import Locked from './pages/locked';
import Banned from './pages/banned';
import SignUpPage from './pages/sign-up';
import ForgotPassword from 'pages/forgot-password';
import NewPassword from 'pages/new-password';
import RequestDisableAdblock from 'pages/request-disable-adblock';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { OutSideLogin, RequireAuth } from './router/auth';
import RequireUserAuth from './router/auth/user/RequireUserAuth';
import AuthLogin from './router/auth/AuthLogin';
import HelmetComponent from 'components/common/helmet';
import LandingPage from './pages/landing-page';
import NotFoundPage from 'components/pages/404';
import ErrorsPage from 'components/pages/500';
import MaitenancePage from 'components/pages/maintenance';
import ErrorsIcon from 'assets/svg/icons/pages/500.svg';
import lazyLoad from 'utils/lazyLoad';
import InjectAxiosInterceptors from 'network/axios/InjectAxiosInterceptors';

const Dashboard = lazyLoad(() => import('./pages/dashboard'));
const Users = lazyLoad(() => import('./pages/admin/Users'));
const SuspenceUser = lazyLoad(() => import('./pages/admin/SuspenceUser'));
const BannedUser = lazyLoad(() => import('./pages/admin/BannedUser'));
const SameIpUser = lazyLoad(() => import('./pages/admin/UserSameIP'));
const ActivePage = lazyLoad(() => import('./pages/sign-up/ActiveAccount'));
//USER
const HomePage = lazyLoad(() => import('./pages/users/HomePage'));
const ManualFaucetPage = lazyLoad(() => import('./pages/users/ManualFaucet'));
const WaittingFaucetPage = lazyLoad(
  () => import('./pages/users/ManualFaucet/Waitting')
);
const ShortLinkPage = lazyLoad(() => import('./pages/users/ShortLink'));
const GetRewardShortLinkPage = lazyLoad(
  () => import('./pages/users/GetRewardShortLink')
);
const WithdrawPage = lazyLoad(() => import('./pages/users/Payment'));
const HistoryPage = lazyLoad(() => import('./pages/users/Payment/History'));
const TasksPage = lazyLoad(() => import('./pages/users/tasks'));
const ReferralPage = lazyLoad(() => import('./pages/users/Referral'));
const DailyBonusPage = lazyLoad(() => import('./pages/users/DailyBonus'));
const RankingPage = lazyLoad(() => import('./pages/users/Ranking'));
const PTCPage = lazyLoad(() => import('./pages/users/PTC'));
const DepositPage = lazyLoad(() => import('./pages/users/Deposit'));
const ExchangePage = lazyLoad(() => import('pages/users/Exchange'));
const CampaingPage = lazyLoad(() => import('./pages/users/Campaign'));
const ManageCampaignPage = lazyLoad(
  () => import('./pages/users/Campaign/ManageCampaign')
);
const OfferwallPage = lazyLoad(() => import('./pages/users/Offerwall'));
const SubOfferPage = lazyLoad(() => import('./pages/users/Offerwall/SubOffer'));
const BannerAdsPage = lazyLoad(
  () => import('components/common/Bitmedia/BitmediaAdsRouterCommon')
);
const BannerCzAdsPage = lazyLoad(
  () => import('components/common/Coinzilla/CoinzillaRouter')
);
const AccountSettingsPage = lazyLoad(
  () => import('./pages/users/AccountSetting')
);
//ADMIN
const FaucetSettingPage = lazyLoad(
  () => import('./pages/admin/Faucet-setting')
);

const GeneralSettingPage = lazyLoad(
  () => import('./pages/admin/GeneralSetting')
);

const CapchaSettingPage = lazyLoad(() => import('./pages/admin/CapchaSetting'));
const AntibotSettingPage = lazyLoad(
  () => import('./pages/admin/AntibotSetting')
);
const ShortLinkSettingPage = lazyLoad(
  () => import('./pages/admin/ShortLinkSetting')
);

const PaymentSettingsPage = lazyLoad(() => import('./pages/admin/Payment'));
const TaskPage = lazyLoad(() => import('./pages/admin/Tasks'));
const DailyBonusAdminPage = lazyLoad(() => import('./pages/admin/DailyBonus'));

const PendingWithdrawPage = lazyLoad(
  () => import('./pages/admin/PendingWithdraw')
);

const PayWithdrawPage = lazyLoad(() => import('./pages/admin/PayWithdraw'));

const DoneWithdrawPage = lazyLoad(() => import('./pages/admin/DonePayment'));

const RejectedWithDrawPage = lazyLoad(
  () => import('pages/admin/RejectedWithdraw')
);

const RefundWithdrawPage = lazyLoad(
  () => import('./pages/admin/RefundWithdraw')
);
const PendingPrizePage = lazyLoad(
  () => import('./pages/admin/Ranking/PendingPrize')
);

const PayPrizePage = lazyLoad(() => import('./pages/admin/Ranking/PayPrize'));

const DonePrizePage = lazyLoad(() => import('./pages/admin/Ranking/DonePrize'));

const UserProfilePage = lazyLoad(() => import('./pages/admin/UserProfile'));
const PtcSettingPage = lazyLoad(() => import('./pages/admin/PTC'));
const PendingCampaignPage = lazyLoad(
  () => import('./pages/admin/PTC/PendingCampaign')
);
const ActiveCampaignPage = lazyLoad(
  () => import('./pages/admin/PTC/ActiveCampaign')
);
const RejectedCampaignPage = lazyLoad(
  () => import('./pages/admin/PTC/RejectedCampaign')
);

const RefundedCampaignPage = lazyLoad(
  () => import('./pages/admin/PTC/RefundedCampaign')
);

const PendingOfferwallPage = lazyLoad(
  () => import('./pages/admin/Offerwall/PendingOfferwall')
);

const ApprovedOfferwallPage = lazyLoad(
  () => import('./pages/admin/Offerwall/ApprovedOfferwall')
);

const RejectedOfferwallPage = lazyLoad(
  () => import('./pages/admin/Offerwall/RejectedOfferwall')
);

const ShortLinkDetailsPage = lazyLoad(
  () => import('./pages/admin/ShortLinkSetting/ShortLinkDetails')
);

const ShortLinkDetailsByUser = lazyLoad(
  () => import('./pages/admin/ShortLinkSetting/ShortLinkByUser')
);

const ShortLinkDetailsToday = lazyLoad(
  () => import('./pages/admin/ShortLinkSetting/ShortLinkTop')
);

const AuthLoginFallBack = withLoading(AuthLogin);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30000,
    },
  },
});

function ErrorFallback({ error, resetErrorBoundary }: any) {
  return (
    // <div role="alert">

    //   <p>Something went wrong:</p>
    //   <pre>{error.message}</pre>
    //   <button onClick={resetErrorBoundary}>Try again</button>
    // </div>

    <div className="flex flex-col justify-center items-center pt-[50px]">
      {/* <div className=" max-w-lg block">
        <img src={ErrorsIcon} alt="icon" className="w-full h-auto"></img>
      </div> */}
      <h1 className="text-5xl py-5">Some thing went wrong !</h1>
      <p className="text-lg text-base16">
        It's always time for a coffee break. We should be back by the time you
        finish your coffee.
      </p>
      <button
        onClick={resetErrorBoundary}
        type="button"
        className="text-white bg-base2 hover:bg-base1 border-1 font-medium rounded-xl text-sm px-5 py-2.5 mr-2 mb-2 mt-[30px] w-[145px]"
      >
        Try Again
      </button>
    </div>
  );
}

export const HOME_PATH = '/admin';
export const USER_HOME_PATH = '/user';
export const ACTIVE_PATH = 'users/verify/:token';
export const NEW_PASSWORD = 'users/new-password/:token';

function App() {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  const userProtectedLaylout = (
    <RequireUserAuth>
      <UserLayout />
    </RequireUserAuth>
  );

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <InjectAxiosInterceptors />
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
        >
          <Routes>
            {/* ADMIN */}
            <Route path={HOME_PATH} element={protectedLayout}>
              <Route index element={<Dashboard />} />
              <Route path="users" element={<Users />} />
              <Route path="user-suspence" element={<SuspenceUser />} />
              <Route path="user-banned" element={<BannedUser />} />
              <Route path="user-same-ip" element={<SameIpUser />} />
              <Route>
                <Route
                  path="general-setting"
                  element={<GeneralSettingPage />}
                />
                <Route path="capcha-setting" element={<CapchaSettingPage />} />
                <Route
                  path="antibot-setting"
                  element={<AntibotSettingPage />}
                />
              </Route>
              <Route path="faucet" element={<FaucetSettingPage />} />
              <Route path="short-link" element={<ShortLinkSettingPage />} />
              <Route
                path="short-link-details"
                element={<ShortLinkDetailsPage />}
              />

              <Route
                path="short-link-details/:id"
                element={<ShortLinkDetailsByUser />}
              />
              <Route
                path="short-link-top"
                element={<ShortLinkDetailsToday />}
              />

              <Route path="payment" element={<PaymentSettingsPage />} />
              <Route path="tasks" element={<TaskPage />} />
              <Route path="daily-bonus" element={<DailyBonusAdminPage />} />

              <Route
                path="pending-withdraw"
                element={<PendingWithdrawPage />}
              />
              <Route path="pay-withdraw" element={<PayWithdrawPage />} />
              <Route path="done-withdraw" element={<DoneWithdrawPage />} />
              <Route
                path="rejected-withdraw"
                element={<RejectedWithDrawPage />}
              />
              <Route path="refund-withdraw" element={<RefundWithdrawPage />} />
              <Route path="pending-prize" element={<PendingPrizePage />} />
              <Route path="pay-prize" element={<PayPrizePage />} />
              <Route path="done-prize" element={<DonePrizePage />} />
              <Route path="user/:id" element={<UserProfilePage />} />
              <Route path="ptc" element={<PtcSettingPage />} />
              <Route
                path="pending-campaign"
                element={<PendingCampaignPage />}
              />
              <Route path="active-campaign" element={<ActiveCampaignPage />} />
              <Route
                path="rejected-campaign"
                element={<RejectedCampaignPage />}
              />
              <Route
                path="refunded-campaign"
                element={<RefundedCampaignPage />}
              />
              <Route
                path="pending-offerwall"
                element={<PendingOfferwallPage />}
              />
              <Route
                path="approved-offerwall"
                element={<ApprovedOfferwallPage />}
              />
              <Route
                path="rejected-offerwall"
                element={<RejectedOfferwallPage />}
              />
            </Route>
            {/* USER */}
            <Route path={USER_HOME_PATH} element={userProtectedLaylout}>
              <Route index element={<HomePage />} />
              <Route path="faucet" element={<ManualFaucetPage />} />
              <Route path="faucet-waitting" element={<WaittingFaucetPage />} />
              <Route path="short-link" element={<ShortLinkPage />} />
              <Route path="ranking" element={<RankingPage />} />
              <Route path="ptc" element={<PTCPage />} />
              <Route path="deposit" element={<DepositPage />} />
              <Route path="exchange" element={<ExchangePage />} />
              <Route path="campaign" element={<CampaingPage />} />
              <Route path="manage-campaign" element={<ManageCampaignPage />} />
              <Route
                path="short-link/:key"
                element={<GetRewardShortLinkPage />}
              />
              <Route path="withdraw" element={<WithdrawPage />} />
              <Route path="history" element={<HistoryPage />} />
              <Route path="tasks" element={<TasksPage />} />
              <Route path="referral" element={<ReferralPage />} />
              <Route path="daily-bonus" element={<DailyBonusPage />} />
              <Route
                path="account-settings"
                element={<AccountSettingsPage />}
              />
              <Route path="offerwall" element={<OfferwallPage />} />
              <Route path="offerwall/:id" element={<SubOfferPage />} />
            </Route>
            {/* AUTH */}
            <Route path="/auth" element={<AuthLoginFallBack />}>
              <Route path="login" element={<LoginPage />} />
            </Route>
            <Route path="/" element={<LandingPage />} />
            <Route path="sign-up" element={<SignUpPage />} />
            <Route path="locked" element={<Locked />} />
            <Route path="banned" element={<Banned />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path={NEW_PASSWORD} element={<NewPassword />} />
            <Route
              path="request-disable-adblock"
              element={<RequestDisableAdblock />}
            />
            <Route path={ACTIVE_PATH} element={<ActivePage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="500" element={<ErrorsPage />} />
            <Route path="maintenance" element={<MaitenancePage />} />
            <Route path="banner/:id" element={<BannerAdsPage />} />
            <Route path="cz/:id" element={<BannerCzAdsPage />} />
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>
      <ToastContainer
        style={{ padding: 0 }}
        toastClassName="toast-body"
        className="toast-body"
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
      {/* Same as */}
    </QueryClientProvider>
  );
}

export default App;
