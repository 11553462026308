import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetComponent = ({ title }) => {
  return (
    <Helmet meta>
      <title>{title}</title>
      <meta name="Noko Faucet" content="High payment faucet" />
    </Helmet>
  );
};

export default HelmetComponent;
