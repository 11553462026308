import { useEffect, useMemo, useState } from 'react';
import {
  LogoIcon,
  OpenSidebarIcon,
  HamburgerCloseIcon,
  HamburgerIcon,
  LogoRoundIcon,
  LoginIcon,
  TicketFillIcon,
  TicketIcon,
  PancakeRoundIcon,
  StarLineIcon,
  StarFillIcon,
} from 'assets/svg';
import avatar from '../../assets/img/avatar.png';
import { Link, useNavigate } from 'react-router-dom';
import useProfile from 'hooks/useProfile';
import AvatarDropdown from './AvatarDropdown';
import ProgressCustom from 'components/progress';
import Logo from 'assets/img/bunnies/bunny7.png';
import CurrencySwitch from 'components/currencies-switch';
import useCurrencies from 'hooks/currencies.hook';
import Earth from 'assets/img/landing-page/earth.png';

type TopHeaderProps = {
  onToggle: (e) => void;
  isOpen: boolean;
};

const TopHeader = ({ onToggle, isOpen }: TopHeaderProps) => {
  const { profile, refetch } = useProfile();
  const { currencies } = useCurrencies();
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, []);

  const percentExp = useMemo(() => {
    return (profile?.exp / profile?.exp_to_next_level) * 100;
  }, [profile?.exp, profile?.exp_to_next_level]);
  return (
    <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-dark dark:border-gray-700">
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center ">
          <div className="flex items-center justify-start ">
            <button
              data-drawer-target="logo-sidebar"
              data-drawer-toggle="logo-sidebar"
              aria-controls="logo-sidebar"
              type="button"
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg  hover:bg-gray-100 focus:outline-nonefocus:ring-gray-200 dark:text-gray-400  ml-[6px]"
              onClick={onToggle}
            >
              <span className="sr-only">Open sidebar</span>
              {isOpen ? <HamburgerCloseIcon /> : <HamburgerIcon />}
            </button>
            <div
              onClick={() => navigate('/')}
              // to="http://localhost:3000"
              className="flex ml-3 md:mr-24 space-x-1 cursor-pointer"
            >
              {/* <img src={Logo} alt="" className="w-[40px] relative left-[8px]" /> */}
              {/* <LogoIcon /> */}
              <img
                src={Earth}
                alt=""
                className="h-[45px] w-auto relative bottom-2 -right-[5px] "
              />
              <span className=" whitespace-nowrap dark:text-white text-[24px] font-[700] text-textMenu pt-[3px] ">
                Noko <span className="text-base5">Faucet</span>
              </span>
            </div>
          </div>
          <div className="absolute right-0 ">
            <div className="grid-cols-6 items-end gap-3 px-2 sm:grid hidden">
              <div className="flex items-center col-span-3 justify-end">
                <CurrencySwitch profile={profile} currencies={currencies} />
              </div>
              {/* <div className="col-span-1">
                <div className="mr-[8px] flex space-x-1 text-textSubtle items-center justify-end">
                  <span className="text-[14px]">{profile?.balance}</span>

                  <div className="hover:scale-110 duration-100 ">
                  <LogoRoundIcon />
                  <PancakeRoundIcon />
                  </div>
                </div>
              </div> */}
              {/* <div className="col-span-1">
                <div className="text-base8 text-[14px] hidden md:flex gap-1">
                  Energy: {profile?.energy}
                  <StarLineIcon className="w-[12px] " />
                </div>
              </div> */}
              <div className="col-span-2 pb-1">
                <div className="text-base5 text-[14px] flex justify-between">
                  <div> Level: {profile?.level}</div>

                  <div>
                    {profile?.exp} / {profile?.exp_to_next_level}
                  </div>
                </div>
                <ProgressCustom fill="bg-base5" percent={percentExp} />
              </div>

              <div className="col-span-1 pr-[20px]">
                <AvatarDropdown />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default TopHeader;
