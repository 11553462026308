import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { authSlice } from './slices/authSlice';
import { taskSlice } from './slices/taskSlice';
import { userSlice } from './slices/userSlice';
import { ptcSlice } from './slices/ptcSlice';
import { rankingSlice } from './slices/rankingSlice';
import { antibotSlice } from './slices/antibotSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    user: userSlice.reducer,
    task: taskSlice.reducer,
    ptc: ptcSlice.reducer,
    ranking: rankingSlice.reducer,
    antibot: antibotSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
