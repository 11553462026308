import { yupResolver } from '@hookform/resolvers/yup';
import ImageCapcha from 'components/ImageCapcha';
import useCapcha from 'hooks/capcha.hook';
import { PtcSchema, PtcSchemaEditOwn } from 'interfaces';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ErrorText, InputControl } from '../forms';
import CustomSelect from '../forms/DropDownCustom';
import {
  QUERY_KEY,
  durationOptions,
  typeOptionsPtc,
} from 'interfaces/constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { sendVerifyCapcha } from 'network/services/auth';
import { useAppDispatch } from 'store/hooks';
import { toast } from 'react-toastify';
import CustomAlert from 'components/alert';
import { sendGetPtcOwnById, sendUpdatePtcOwn } from 'network/services/user';
import { useSelector } from 'react-redux';
import { ptcSelector, setIdEditPtc } from 'store/slices/ptcSlice';

const ModalGlobal = ({ title, isOpen }) => {
  const { capcha, refetch: refetchCapcha } = useCapcha();
  const [selectedX, setSelectedX] = useState(-1);
  const [selectedY, setSelectedY] = useState(-1);
  const [durationValue, setDurationValue] = useState(5);
  const [typeValue, setTypeValue] = useState('All Device');
  const dispatch = useAppDispatch();
  const dataRef = useRef(null as any);
  const { idPtcEdit } = useSelector(ptcSelector);
  const queryClient = useQueryClient();

  const ptcValidationSchema = yup
    .object({
      title: yup.string().required('Title is required !'),
      url: yup.string().trim().required('Url is required !'),
      description: yup.string().required('Description is required !'),
    })
    .required();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm<PtcSchemaEditOwn>({
    defaultValues: {
      title: '',
      description: '',
      url: '',
    },
    resolver: yupResolver(ptcValidationSchema),
  });
  const handleClose = () => {
    dispatch(setIdEditPtc(null));
  };

  const {
    data: PtcById,
    isLoading,
    refetch,
  } = useQuery(
    [QUERY_KEY.GET_PTC_OWN_BY_ID, idPtcEdit],
    () => sendGetPtcOwnById(idPtcEdit || ''),
    {
      keepPreviousData: false,
      retry: false,
      cacheTime: 2,
      enabled: !!idPtcEdit,
    }
  );

  const dataPtcById = useMemo(() => {
    if (!PtcById) return null;
    return PtcById;
  }, [PtcById]);

  useEffect(() => {
    if (dataPtcById) {
      setValue('title', dataPtcById?.title);
      setValue('description', dataPtcById?.description);
      setValue('url', dataPtcById?.url);
    }
  }, [dataPtcById, setValue]);

  const handleChangeField = (e: any, fieldName: any) => {
    setValue(fieldName, e.target.value);
  };

  const handleEditPtc = useMutation(
    (data: any) => sendUpdatePtcOwn(idPtcEdit || '', data),
    {
      onSuccess: (metaData) => {
        toast(<CustomAlert type="Success" content="Claim successed" />, {
          toastId: 1,
        });
        queryClient.invalidateQueries(QUERY_KEY.GET_CAMPAIGN_PAGINATION);
        handleClose();
        refetchCapcha();
        reset();
      },
      onError: (error: any) => {
        toast(<CustomAlert type="Warning" content="Claim failed!" />, {
          toastId: 1,
        });
        handleClose();
        refetchCapcha();
        reset();
      },
    }
  );

  const handleVerifyCapcha = useMutation(
    (data: any) => sendVerifyCapcha(data),
    {
      onSuccess: (metaData) => {
        setSelectedX(-1);
        setSelectedY(-1);
        // setIsOpen(false);
        handleEditPtc.mutate(dataRef?.current);
        // handleLoginAccount.mutate(dataLogin);
      },
      onError: (error: any) => {
        refetchCapcha();
        setSelectedX(-1);
        setSelectedY(-1);

        toast(<CustomAlert type="Warning" content="Capcha failed!" />, {
          toastId: 1,
        });
      },
    }
  );
  const handleSubmitForm: SubmitHandler<PtcSchemaEditOwn> = (data) => {
    const capChabody = {
      captchaId: capcha?._id,
      selectedX,
      selectedY,
    };

    handleVerifyCapcha.mutate(capChabody);
    dataRef.current = data;
  };

  return (
    <div>
      {isOpen && (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full  bg-gray-800 bg-opacity-50 z-50 h-screen">
          <div className="bg-white p-4 rounded-2xl shadow-lg">
            {/* Pop-up content */}
            <h2 className="text-xl font-bold mb-4">{title}</h2>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <div className="grid grid-cols-1">
                <div>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <>
                        <InputControl
                          type="text"
                          label="Title"
                          placeholder="Type your Title"
                          {...field}
                          onChange={(e) => handleChangeField(e, 'title')}
                        />
                        {errors.title && (
                          <ErrorText>{errors.title.message}</ErrorText>
                        )}
                      </>
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <>
                        <InputControl
                          type="text"
                          label="Description"
                          placeholder="Type your Description"
                          {...field}
                          onChange={(e) => handleChangeField(e, 'description')}
                        />
                        {errors.description && (
                          <ErrorText>{errors.description.message}</ErrorText>
                        )}
                      </>
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="url"
                    control={control}
                    render={({ field }) => (
                      <>
                        <InputControl
                          type="text"
                          label="Url"
                          placeholder="Type your url"
                          {...field}
                          onChange={(e) => handleChangeField(e, 'url')}
                        />
                        {errors.url && (
                          <ErrorText>{errors.url.message}</ErrorText>
                        )}
                      </>
                    )}
                  />
                </div>
                <div>
                  By default, user can see your adsvertisement once per day -
                  24h
                </div>
              </div>

              <div className="text-center">
                <ImageCapcha
                  capcha={capcha}
                  setSelectedX={setSelectedX}
                  setSelectedY={setSelectedY}
                  selectedX={selectedX}
                  selectedY={selectedY}
                />
              </div>
              <div className="flex gap-2 justify-end">
                <button
                  type="submit"
                  className="mt-4 bg-base2 hover:bg-base3 text-white font-bold py-2 px-4 rounded"
                  // onClick={handleSubmit}
                >
                  Submit
                </button>
                <button
                  className="mt-4 bg-base5 hover:base6 text-white font-bold py-2 px-4 rounded"
                  // onClick={togglePopUp}
                  onClick={handleClose}
                >
                  Cancle
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalGlobal;
