import { useDetectAdBlock } from 'adblock-detect-react';
import CustomAlert from 'components/alert';
import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isValidToken } from 'utils/auth';

const RequireUserAuth: React.FC<any> = ({ children }) => {
  const isAuth = isValidToken();
  const navigate = useNavigate();
  const adBlockDetected = useDetectAdBlock();
  // const location = useLocation();

  useEffect(() => {
    if (adBlockDetected) {
      navigate('/request-disable-adblock');
      toast(<CustomAlert type="Warning" content="Please disable adblock!" />, {
        toastId: 1,
      });
    }
  }, [adBlockDetected, navigate]);

  useEffect(() => {
    if (!isAuth) {
      toast(<CustomAlert type="Warning" content="Your session is expried!" />, {
        toastId: 1,
      });
      navigate('/auth/login', { replace: true });
    }
  }, [isAuth, navigate]);

  return isAuth ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireUserAuth;
