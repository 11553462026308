import { useState, useEffect } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const useFingerprint = () => {
  const [fingerprint, setFingerprint] = useState(null as any);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null as any);

  useEffect(() => {
    const getFingerprint = async () => {
      try {
        setIsLoading(true);
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        setFingerprint(result.visitorId);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    getFingerprint();
  }, []);

  return { fingerprint, isLoading, error };
};

export default useFingerprint;
