import React from 'react';
import { useNavigate } from 'react-router-dom';

const RequestDisableAdblock = () => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center items-center  h-screen text-center">
      <div>
        <p className="text-[48px]">Aww, Snap!</p>
        <div className="text-[21px">
          Please disable adblock and similar extensions , we would greatly
          appreciate it. Thank you for your support !
        </div>
        <button
          onClick={() => navigate(-1)}
          type="button"
          className="text-white bg-base2 hover:bg-base1 border-1 font-medium rounded-xl text-sm px-5 py-2.5 mr-2 mb-2 mt-[30px] w-[145px]"
        >
          Reload
        </button>
      </div>
    </div>
  );
};

export default RequestDisableAdblock;
