import { QUERY_KEY } from 'interfaces/constants';
import { sendGetAllUser } from 'network/services/admin';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import useGeneralSettings from 'hooks/generalSetting.hook';
import { Breadcrumb, Tabs } from 'antd';
import { ETabDashboard } from 'interfaces/dashboard';
import { OverView } from './Overview';
import PaymentTab from './Payment';
import FaucetTab from './FaucetTab';
import ShortLinkTab from './ShortLinkTab';

const Dashboard = () => {
  const { generalSettings } = useGeneralSettings();
  const { data: dataUser } = useQuery(
    [QUERY_KEY.GET_ALL_USER],
    () => sendGetAllUser(),
    {
      retry: 1,
      keepPreviousData: true,
    }
  );

  const userList = useMemo(() => {
    return dataUser || {};
  }, [dataUser]);

  const tabs = [
    {
      label: ETabDashboard.OVERVIEW,
      key: ETabDashboard.OVERVIEW,
      children: (
        <OverView
          userList={userList}
          dataUser={dataUser}
          generalSetting={generalSettings}
        />
      ),
    },
    {
      label: ETabDashboard.PAYMENT,
      key: ETabDashboard.PAYMENT,
      chldren: <PaymentTab />,
    },
    {
      label: ETabDashboard.FAUCET,
      key: ETabDashboard.FAUCET,
      chldren: <FaucetTab />,
    },
    {
      label: ETabDashboard.SHORT_URL,
      key: ETabDashboard.SHORT_URL,
      chldren: <ShortLinkTab />,
    },
  ];

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: 'Admin',
          },
          {
            title: 'Dashboard',
          },
        ]}
      />
      <h1 className="font-bold text-[26px]">Dashboard</h1>
      <Tabs defaultActiveKey="1" items={tabs} />
    </>
  );
};

export default Dashboard;
