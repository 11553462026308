import React, { useEffect, useRef, useState } from 'react';
import lockedIcon from 'assets/svg/icons/pages/locked.svg';
import { useNavigate } from 'react-router-dom';
import { LogoutIcon } from 'assets/svg';
import { useQueryClient } from 'react-query';
import { QUERY_KEY } from 'interfaces/constants';
import useProfile from 'hooks/useProfile';

const Banned = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleSignOut = () => {
    navigate('/auth/login');
    localStorage.removeItem('token');
    localStorage.removeItem('isAuthenticated');
    queryClient.setQueryData([QUERY_KEY.GET_ROLE], null);
  };

  return (
    <div className="flex flex-col justify-center items-center pt-[50px]">
      <div className=" max-w-lg block">
        <img src={lockedIcon} alt="icon" className="w-full h-auto"></img>
      </div>
      <h1 className="text-5xl py-2 text-base5">Banned !</h1>
      <p className="text-lg text-base16 text-center pb-5">
        Your account has been banned!
      </p>
      <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
        <li>Please don't try to use bot</li>
        <li>VPN/Proxy is not allowed</li>
        <li>Multiple accounts is not allowed</li>
        <li>
          Contact us: <span className="text-base3"> Nokofaucet@gmail.com</span>
        </li>
      </ul>
      <div className="flex justify-center items-center">
        <button
          onClick={handleSignOut}
          type="button"
          className=" border-[1.5px] text-textDisabled  rounded-xl  border-[#BDC2C4] font-medium text-sm px-5 py-2.5 mr-2 mb-2 mt-[30px] w-[145px]"
        >
          <div className="flex justify-center items-center gap-2">
            <LogoutIcon fill="#BDC2C4" /> <span>Logout</span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Banned;
