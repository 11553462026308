import diamond1 from 'assets/img/bonus/diamond1.svg'
import diamond2 from 'assets/img/bonus/diamond2.svg'
import diamond3 from 'assets/img/bonus/diamond3.svg'
import diamond4 from 'assets/img/bonus/diamond4.svg'
import diamond5 from 'assets/img/bonus/diamond5.svg'
import diamond6 from 'assets/img/bonus/diamond6.svg'
import diamond7 from 'assets/img/bonus/diamond7.svg'

export const QUERY_KEY = {
    GET_CURRENT_USER: 'GET_CURRENT_USER',
    GET_FAUCET_SETTINGS: 'GET_FAUCET_SETTINGS',
    GET_SHORT_LINK_PAGINATION: 'GET_SHORT_LINK_PAGINATION',
    GET_SHORT_LINK_PAGINATION_ADMIN: 'GET_SHORT_LINK_PAGINATION_ADMIN',
    GET_SHORT_LINK_BY_ID: 'GET_SHORT_LINK_BY_ID',
    GET_POOLS: 'GET_POOLS',
    GET_PAYMENT_PAGINATION: 'GET_PAYMENT_PAGINATION',
    GET_PAYMENT_BY_ID: 'GET_PAYMENT_BY_ID',
    GET_ALL_PAYMENT: 'GET_ALL_PAYMENT',
    GET_CURRENCIES: 'GET_CURRENCIES',
    GET_WITHDRAW_HISTORY_PAGINATION: 'GET_WITHDRAW_HISTORY_PAGINATION',
    GET_ROLE: 'GET_ROLE',
    GET_ACTIVE_USER: 'GET_ACTIVE_USER',
    GET_USERS_PAGINATION: 'GET_USERS_PAGINATION',
    GET_TASKS_PAGINATION: 'GET_TASKS_PAGINATION',
    GET_TASK_BY_ID: 'GET_TASK_BY_ID',
    GET_HISTORY: 'GET_HISTORY',
    GET_CHECKIN_INFO: 'GET_CHECKIN_INFO',
    GET_COIN_PRICE: 'GET_COIN_PRICE',
    GET_ALL_BONUS: 'GET_ALL_BONUS',
    GET_BONUS_BY_ID: 'GET_BONUS_BY_ID',
    GET_GENERAL_SETTING: 'GET_GENERAL_SETTING',
    GET_COMMISSION_PAGINATION: 'GET_COMMISSION_PAGINATION',
    GET_ALL_USER: 'GET_ALL_USER',
    GET_USER_BY_ID: 'GET_USER_BY_ID',
    GET_USER_SAME_IP: 'GET_USER_SAME_IP',
    GET_CAPCHA: 'GET_CAPCHA',
    GET_REWARD_SHORT_LINK: 'GET_REWARD_SHORT_LINK',
    GET_HISTORY_BY_USER_ID: 'GET_HISTORY_BY_USER_ID',
    GET_DEPOSIT_PAGINATION: 'GET_DEPOSIT_PAGINATION',
    GET_CAMPAIGN_PAGINATION: 'GET_CAMPAIGN_PAGINATION',
    GET_CAMPAIGN_PAGINATION_ADMIN: 'GET_CAMPAIGN_PAGINATION_ADMIN',
    GET_CAMPAIGN_PAGINATION_USER: 'GET_CAMPAIGN_PAGINATION_USER',
    GET_CAMPAIGN_FOR_USER: 'GET_CAMPAIGN_FOR_USER',
    GET_PTC_OWN_BY_ID: 'GET_PTC_OWN_BY_ID',
    GET_OFFERWALL_HISTORY: 'GET_OFFERWALL_HISTORY',
    GET_OFFERWALL_HISTORY_ADMIN: 'GET_OFFERWALL_HISTORY_ADMIN',
    GET_CAPCHA_BY_ADMIN: 'GET_CAPCHA_BY_ADMIN',
    GET_RANKING_USER: 'GET_RANKING_USER',
    GET_RANKING_PAYMENT_PAGINATION: 'GET_RANKING_PAYMENT_PAGINATION',
    GEN_ANTIBOT: 'GEN_ANTIBOT',
    GET_ANTIBOT_BY_ADMIN: 'GET_ANTIBOT_BY_ADMIN',
    GET_INIT_INFO: 'GET_INIT_INFO',
    GET_SHORT_LINK_DETAILS_ADMIN: 'GET_SHORT_LINK_DETAILS_ADMIN',
    GET_HISTORY_SHORTLINK_TOP: 'GET_HISTORY_SHORTLINK_TOP',


}

export const columnShortLink = [
    {
        key: '_id',
        title: 'ID',
        dataIndex: '_id',
    },
    {
        key: 'title',
        title: 'Name',
        dataIndex: 'title',
    },
    {
        key: 'des',
        title: 'Description',
        dataIndex: 'des',
    },
    {
        key: 'url',
        title: 'Url',
        dataIndex: 'url',
    },
    {
        key: 'reward',
        title: 'Reward',
        dataIndex: 'reward',
    },
    {
        key: 'view_per_day',
        title: 'View/Day',
        dataIndex: 'view_per_day',
    },
    {
        key: 'energy_per_view',
        title: 'Energy/view',
        dataIndex: 'energy_per_view',
    },
    {
        key: 'exp_per_view',
        title: 'Exp/View',
        dataIndex: 'exp_per_view',
    },
];

export const paymentColumn = [
    {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
    },
    {
        key: 'type',
        title: 'Method',
        dataIndex: 'type',
    },
    {
        key: 'code',
        title: 'Code',
        dataIndex: 'code',
    },
    {
        key: 'minimum_amount',
        title: 'Minimum Amount',
        dataIndex: 'minimum_amount',
    },


];

export const withDrawHistory = [
    {
        key: 'wallet',
        title: 'Wallet',
        dataIndex: 'wallet',
    },
    {
        key: 'amount',
        title: 'Amount',
        dataIndex: 'amount',
    },
    {
        key: 'code',
        title: 'Currency',
        dataIndex: 'code',
    },
    {
        key: 'date',
        title: 'Date',
        dataIndex: 'date',
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
    },

];

export const rankingPayment = [
    {
        key: 'type',
        title: 'Type',
        dataIndex: 'type',
    },
    {
        key: 'amount',
        title: 'Amount',
        dataIndex: 'amount',
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
    },
    {
        key: 'date',
        title: 'Date',
        dataIndex: 'date',
    },
];

export const symbolCurrency = [
    { id: 1, label: 'Bitcoin', value: 'bitcoin', code: 'BTC' },
    { id: 2, label: 'Ethereum', value: 'ethereum', code: 'ETH' },
    { id: 3, label: 'Tether', value: 'tether', code: 'USDT' },
    { id: 4, label: 'Tron', value: 'tron', code: 'TRX' },
    { id: 5, label: 'Dogecoin', value: 'dogecoin', code: 'DOGE' },
];

export const pendingWithdraw = [
    {
        key: 'username',
        title: 'UserName',
        dataIndex: 'username',
    },
    {
        key: 'balance',
        title: 'Balance',
        dataIndex: 'balance',
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
    },
    {
        key: 'wallet',
        title: 'Wallet',
        dataIndex: 'wallet',
    },
    {
        key: 'amount',
        title: 'Amount',
        dataIndex: 'amount',
    },
    {
        key: 'date',
        title: 'Date',
        dataIndex: 'date',
    },
    // {
    //     key: 'method',
    //     title: 'Method',
    //     dataIndex: 'method',
    // },
    {
        key: 'code',
        title: 'Currency',
        dataIndex: 'code',
    },
    // {
    //     key: 'status',
    //     title: 'Status',
    //     dataIndex: 'status',
    // },
];

export const pendingWithdrawRanking = [
    {
        key: 'username',
        title: 'UserName',
        dataIndex: 'username',
    },
    {
        key: 'type',
        title: 'Type',
        dataIndex: 'type',
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
    },

    {
        key: 'amount',
        title: 'Amount',
        dataIndex: 'amount',
    },
    {
        key: 'date',
        title: 'Date',
        dataIndex: 'date',
    },
];


export const users = [
    {
        key: 'username',
        title: 'UserName',
        dataIndex: 'username',
    },
    {
        key: 'email',
        title: 'Email',
        dataIndex: 'email',
    },
    {
        key: 'referralId',
        title: 'referralId',
        dataIndex: 'referralId',
    },

    {
        key: 'ipAddress',
        title: 'Ip',
        dataIndex: 'ipAddress',
    },
    {
        key: 'country',
        title: 'Country',
        dataIndex: 'country',
    },
    {
        key: 'balance',
        title: 'Balance',
        dataIndex: 'balance',
    },
    {
        key: 'date',
        title: 'Register Date',
        dataIndex: 'date',
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
    },
    {
        key: 'level',
        title: 'Level',
        dataIndex: 'level',
    }
    // {
    //     key: 'method',
    //     title: 'Method',
    //     dataIndex: 'method',


]

export const dailyBonus = [
    {
        id: 1,
        title: 'Day 1',
        reward: 50,
        img: diamond1
    },
    {
        id: 2,
        title: 'Day 2',
        reward: 50,
        img: diamond2
    },
    {
        id: 3,
        title: 'Day 3',
        reward: 50,
        img: diamond3
    },
    {
        id: 4,
        title: 'Day 4',
        reward: 100,
        img: diamond4
    },
    {
        id: 5,
        title: 'Day 5',
        reward: 100,
        img: diamond5
    },
    {
        id: 6,
        title: 'Day 6',
        reward: 150,
        img: diamond6
    },
    {
        id: 7,
        title: 'Day 7',
        reward: 500,
        img: diamond7
    },
]

export const columnBonus = [
    {
        key: 'day',
        title: 'Day',
        dataIndex: 'day',
    },
    {
        key: 'reward',
        title: 'Reward',
        dataIndex: 'reward',
    },
    {
        key: 'exp',
        title: 'Exp',
        dataIndex: 'exp',
    },
];

export const columnReferral = [
    {
        key: 'username',
        title: 'user',
        dataIndex: 'username',
    },
    {
        key: 'createdAt',
        title: 'Joined At',
        dataIndex: 'createdAt',
    },
    // {
    //     key: 'total_earned',
    //     title: 'Total User Earned',
    //     dataIndex: 'total_earned',
    // },
    {
        key: 'your_comission',
        title: 'Your Comission',
        dataIndex: 'your_comission',
    },

];

export const DepositHistoryColumn = [
    {
        key: 'transactionId',
        title: 'Transaction ID',
        dataIndex: 'transactionId',
    },
    {
        key: 'amount',
        title: 'Amount',
        dataIndex: 'amount',
    },
    {
        key: 'date',
        title: 'Date',
        dataIndex: 'date',
    },
    {
        key: 'method',
        title: 'Method',
        dataIndex: 'method',
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
    },
];

export const pendingPtc = [
    {
        key: 'userName',
        title: 'UserName',
        dataIndex: 'userName',
    },
    {
        key: 'status',
        title: 'Status User',
        dataIndex: 'status',
    },
    {
        key: 'title',
        title: 'Title',
        dataIndex: 'title',
    },
    {
        key: 'url',
        title: 'url',
        dataIndex: 'url',
    },
    {
        key: 'remainView',
        title: 'Remain view',
        dataIndex: 'remainView',
    },

    {
        key: 'total_view',
        title: 'Total view',
        dataIndex: 'total_view',
    },
    {
        key: 'duration',
        title: 'Duration',
        dataIndex: 'duration',
    },
    {
        key: 'statusCampaign',
        title: 'Status',
        dataIndex: 'statusCampaign',
    },
    {
        key: 'date',
        title: 'Date',
        dataIndex: 'date',
    },

];

export const durationOptions = [
    { id: 1, value: 5, label: '5 seconds' },
    { id: 2, value: 15, label: '15 seconds' },
    { id: 3, value: 30, label: '30 seconds' },
    { id: 4, value: 60, label: '60 seconds' },
];

export const typeOptionsPtc = [{ id: 1, value: 'All Device', label: 'All Device' }];

export const offerwallHistoryColumn = [
    {
        key: 'trans_id',
        title: 'Trans_id',
        dataIndex: 'trans_id',
    },
    {
        key: 'offer_id',
        title: 'Offer_id',
        dataIndex: 'offer_id',
    },
    {
        key: 'amount',
        title: 'Amount',
        dataIndex: 'amount',
    },
    {
        key: 'date',
        title: 'Date',
        dataIndex: 'date',
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
    },

];

export const offerwallHistoryAdminColumn = [
    {
        key: 'user_name',
        title: 'user_name',
        dataIndex: 'user_name',
    },
    {
        key: 'user_status',
        title: 'User status',
        dataIndex: 'user_status',
    },
    {
        key: 'trans_id',
        title: 'Trans_id',
        dataIndex: 'trans_id',
    },
    {
        key: 'offer_id',
        title: 'Offer_id',
        dataIndex: 'offer_id',
    },
    {
        key: 'ip_click',
        title: 'IP Click',
        dataIndex: 'ip_click',
    },
    {
        key: 'amount',
        title: 'Amount',
        dataIndex: 'amount',
    },
    {
        key: 'date',
        title: 'Date',
        dataIndex: 'date',
    },
];

export const capchaAdminColumn = [
    {
        key: '_id',
        title: 'ID',
        dataIndex: '_id',
    },
    {
        key: 'reversedAnimal',
        title: 'reversedAnimal',
        dataIndex: 'reversedAnimal',
    },
    {
        key: 'selectedX',
        title: 'selectedX',
        dataIndex: 'selectedX',
    },
    {
        key: 'selectedY',
        title: 'selectedY',
        dataIndex: 'selectedY',
    },
    {
        key: 'createdAt',
        title: 'createdAt',
        dataIndex: 'createdAt',
    },
];

export const AntibotAdminColumn = [
    {
        key: '_id',
        title: 'ID',
        dataIndex: '_id',
    },
    {
        key: 'createdAt',
        title: 'createdAt',
        dataIndex: 'createdAt',
    },
];









