import React from 'react';
import Telephone from 'assets/img/landing-page/telephone.png';
import FeaturItem from './FeaturItem';
import Feature1 from 'assets/img/landing-page/feature1.png';
import Feature2 from 'assets/img/landing-page/feature2.png';
import Feature3 from 'assets/img/landing-page/feature3.png';
import ScrollDiv from '../ScrollDiv';

const dummy = [
  {
    id: 1,
    title: 'Faucet',
    des: 'You just need to click a button to get the reward, but the button only enables every 5 minutes.',
    icon: Feature1,
  },
  // {
  //   id: 2,
  //   title: 'Short Link',
  //   des: 'View the shortlink to earn reward',
  //   icon: <ShareIcon width={50} fill="#280D5F" />,
  // },
  {
    id: 3,
    title: 'Daily Bonus',
    des: 'This task is very comfortable to complete, you just need to login everyday to receive your coins.',
    icon: Feature2,
  },
  // {
  //   id: 4,
  //   title: 'PTC',
  //   des: 'Promote your website or view other campaigns to earn rewards',
  //   icon: BunnyCardsIcon,
  // },
  {
    id: 5,
    title: 'Referral Commission',
    des: 'Share your referral link with your friends and earn up to 20% + Items of all their earnings for lifetime.',
    icon: Feature3,
  },
  // {
  //   id: 6,
  //   title: 'Tasks',
  //   des: 'Completed tasks to get rewards',
  //   icon: <MedalTealIcon width={56} />,
  // },
];
const Feature = ({ ref1 }) => {
  return (
    <div
      className="lg:mx-[100px] 2xl:mx-[120px] grid grid-cols-1 lg:grid-cols-2"
      ref={ref1}
    >
      <ScrollDiv
        pageYOffset={2100}
        animate="animate__fadeInUp animate__animated animate__delay-0.7s h-auto"
      >
        <div className="left-content pt-[60px] lg:pt-[30px] lg:px-[72px] ">
          <div className="text-[48px] py-3 font-bold lg:text-[32px] text-center lg:text-left">
            Features{' '}
          </div>
          {/* <div className="text-control text-[14px]">
            Anytime, anywhere. Multiple earning opportunities.
          </div> */}
          <div className="pt-[32px] grid grid-cols-1 lg:gap-5 gap-[50px]">
            {dummy.map((item) => {
              return <FeaturItem item={item} />;
            })}
          </div>
        </div>
      </ScrollDiv>
      <div className="right-content">
        <img src={Telephone} alt="" />
      </div>
    </div>
  );
};

export default Feature;
