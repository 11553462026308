import {
  BitcoinIcon,
  DogeIcon,
  EthereumIcon,
  TrxIcon,
  UsdtIcon,
} from 'assets/svg';
import PriceCountUp from 'components/price-count-up';
import React, { useMemo } from 'react';

const CointItem = ({ item, dataCoin, selected, setSelected, index }) => {
  const getFaucetIcon = useMemo(() => {
    switch (item.code) {
      case 'BTC':
        return <BitcoinIcon width={40} />;
      case 'ETH':
        return <EthereumIcon width={40} />;
      case 'USDT':
        return <UsdtIcon width={40} />;
      case 'TRX':
        return <TrxIcon width={40} />;
      case 'DOGE':
        return <DogeIcon width={40} />;
      default:
        return null;
    }
  }, [item?.code]);

  const dataCoinFilter = useMemo(() => {
    return dataCoin?.find((i: any) => i?.currency === item?.currency);
  }, [dataCoin, item?.currency]);

  return (
    <div
      className={`px-[32px] py-[24px] mx-5 ${
        selected ? 'bg-white rounded-2xl' : null
      }`}
      onClick={() => setSelected(index)}
    >
      <div className=" pb-[8px] flex items-center justify-between">
        <>{getFaucetIcon}</>

        {/* <div className="pl-2 text-[#808A9D] text-[16px]">{item?.code}</div> */}
      </div>
      <div className="flex items-center">
        <p className="text-[12px] text-base16"> {item.code}/USTD</p>
        <span className="mx-4 min-w-[48px] bg-base7 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded-lg dark:bg-green-900 dark:text-green-300 text-[16px]">
          {item?.code}
        </span>
      </div>

      <div className="py-[4px] flex items-center text-[28px] ">
        <PriceCountUp
          start={0}
          endValue={dataCoinFilter?.price}
          prefix=""
          decimals={3}
          separator={','}
        />
        {/* <p className="text-[28px] ">{dataCoinFilter?.price} </p> */}
      </div>
      <p className="text-[12px]">{dataCoinFilter && dataCoinFilter?.price}</p>
    </div>
  );
};

export default CointItem;
