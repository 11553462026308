import React, { useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import { marginBottom, marginTop, padding, paddingTop } from 'styled-system';

const PieChartAnalytics = ({ historyData }) => {
  const config = useMemo(() => {
    if (!historyData) return [];

    const data = historyData?.usersCountByCountry
      ?.map((item: any) => ({
        value: item.count,
        name: item?._id,
      }))
      .sort(function (a, b) {
        return b.value - a.value;
      });

    // Limit the number of segments
    var maxSegments = 8;
    var displayData = data?.slice(0, maxSegments);
    var othersValue = data?.slice(maxSegments)?.reduce(function (sum, item) {
      return sum + item.value;
    }, 0);

    // Add the "Others" category if needed
    if (othersValue > 0) {
      displayData.push({ value: othersValue, name: 'Others' });
    }
    return displayData;
  }, [historyData]);

  const option = {
    title: {
      text: 'Total Users',
      subtext: 'User analytics',
      left: 'start',
    },

    tooltip: {
      trigger: 'item',
    },
    legend: {
      left: 'center',
      marginBottom: 10,
      show: false,
    },
    series: [
      {
        color: [
          '#452A7A',
          '#58BD7D',
          '#FFB237',
          '#ff4b7f',
          '#00c2ff',
          '#31D0AA',
          '#D67E0A',
          '#7645D9',
          '#ff3891',
        ],
        top: '20px',
        name: 'Access From',
        type: 'pie',
        radius: ['50%', '80%'],
        // roseType: 'area',
        // radius: '100%',
        data: config,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
    graphic: {
      type: 'text',
      left: 'center',
      top: '145px',
      align: 'center',
      paddingTop: '50px',
      style: {
        text: `${historyData?.totalUser}`,
        textAlign: 'center',
        fill: '#280D5F',
        fontSize: 32,
        fontWeight: 'bold',
      },
    },
  };

  return (
    <div className="lg:h-[256px] h-[700px] xl:h-[300px] ">
      <ReactECharts option={option} style={{ height: '100%', width: '100%' }} />
    </div>
  );
};

export default PieChartAnalytics;
