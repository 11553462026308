import { useEffect, useState } from 'react';
import { ArrowDropDownIcon, ArrowDropUpIcon } from 'assets/svg';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const SubMenu = ({ menuItem, isOpen, onToggle, location }: any) => {
  const navigate = useNavigate();
  const isSubMenu = menuItem?.children;
  const [isShowSubMenu, setIsShowSubMenu] = useState(false);
  const isMobileScreen = useMediaQuery({ query: '(max-width: 640px)' });

  const handleClick = (e: any) => {
    if (isOpen) {
      setIsShowSubMenu(!isShowSubMenu);
    } else {
      onToggle();
      setIsShowSubMenu(true);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setIsShowSubMenu(false);
    }
  }, [isOpen]);

  return (
    <>
      {isSubMenu && (
        <div>
          <div
            className="select-none flex text-textMenu font-[16px] space-x-3 hover:bg-gray-100 px-6 items-center h-[48px] w-full cursor-pointer"
            onClick={handleClick}
          >
            <div className="text-center">{menuItem?.icon}</div>
            {isOpen ? (
              <>
                <div className="w-[55px]">{menuItem.title}</div>
                <div className="pl-24">
                  {isShowSubMenu ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </div>
              </>
            ) : null}
          </div>
          {isShowSubMenu &&
            isSubMenu.map((item: any, idx: number) => (
              <Link
                key={idx}
                to={item.path}
                onClick={isMobileScreen ? () => onToggle() : () => {}}
              >
                <div
                  className={`flex text-textMenu space-x-3 hover:bg-gray-100 px-6 items-center py-3 w-full pl-14 font-[14px] ${
                    item.path === location.pathname
                      ? 'bg-gray-100'
                      : 'border-transparent'
                  }`}
                >
                  <div className="text-[14px]">{item.title}</div>
                </div>
              </Link>
            ))}
        </div>
      )}
    </>
  );
};

export default SubMenu;
