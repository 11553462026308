import React, { useEffect, useRef, useState } from 'react';
import lockedIcon from 'assets/svg/icons/pages/locked.svg';
import { useNavigate } from 'react-router-dom';
import { LogoutIcon } from 'assets/svg';
import { useQueryClient } from 'react-query';
import { QUERY_KEY } from 'interfaces/constants';
import useProfile from 'hooks/useProfile';

const Locked = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [remainTime, setRemainTime] = useState(null as any);
  const ref = useRef(null as any);

  const handleSignOut = () => {
    navigate('/auth/login');
    localStorage.removeItem('token');
    localStorage.removeItem('isAuthenticated');
    queryClient.setQueryData([QUERY_KEY.GET_ROLE], null);
  };
  useEffect(() => {
    const locked_until = localStorage.getItem('locked_until');
    locked_until && setRemainTime(locked_until);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const elapsedTime = new Date(remainTime).getTime() - Date.now();
      if (elapsedTime <= 0) {
        clearInterval(intervalId);
        ref.current.innerHTML = `<span style="color:#1FC7D4;margin-left:10px">Unlocked</span>`;
      } else {
        if (ref.current) {
          const minutes = Math.floor(elapsedTime / 1000 / 60);
          const seconds = Math.floor(elapsedTime / 1000) % 60;
          const mituntesWithZero = ('0' + minutes).slice(-2);
          const secondsWithZero = ('0' + seconds).slice(-2);
          ref.current.innerHTML = `Unlock after : <span style="color:#D130AA;margin-left:10px">${mituntesWithZero}  : ${secondsWithZero}</span>`;
        }
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [remainTime]);

  return (
    <div className="flex flex-col justify-center items-center pt-[50px]">
      <div className=" max-w-lg block">
        <img src={lockedIcon} alt="icon" className="w-full h-auto"></img>
      </div>
      <h1 className="text-5xl py-5">Locked !</h1>
      <p className="text-lg text-base16 text-center">
        Your account is temporarily locked !
      </p>
      <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
        <li>Do not try to use bot</li>
        <li>VPN/Proxy is not allowed</li>
        <li>Multiple accounts is not allowed</li>
      </ul>
      <div className="text-base4 font-semibold mt-3 text-[20px]" ref={ref}>
        locked time
      </div>
      <div className="flex justify-center items-center">
        <button
          onClick={() => navigate('/user')}
          type="button"
          className="text-white bg-base2 hover:bg-base1 border-1 font-medium rounded-xl text-sm px-5 py-2.5 mr-2 mb-2 mt-[30px] w-[145px]"
        >
          Back
        </button>
        <button
          onClick={handleSignOut}
          type="button"
          className=" border-[1.5px] text-textDisabled  rounded-xl  border-[#BDC2C4] font-medium text-sm px-5 py-2.5 mr-2 mb-2 mt-[30px] w-[145px]"
        >
          <div className="flex justify-center items-center gap-2">
            <LogoutIcon fill="#BDC2C4" /> <span>Logout</span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Locked;
