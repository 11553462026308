import * as React from 'react';

export const Label = ({
  label,
  ...props
}: { label: string } & React.ComponentProps<'label'>) => {
  return (
    <label
      {...props}
      className="block text-blueGray-600 text-sm mb-2 text-control"
    >
      {label}
    </label>
  );
};
