import React from 'react';
import Maitenance from 'assets/svg/icons/pages/maintenance.svg';
import { useNavigate } from 'react-router-dom';

const MaitenancePage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="py-2 flex justify-center">
        <div
          dangerouslySetInnerHTML={{
            __html: `
            <iframe data-aa='2214127' src='//ad.a-ads.com/2214127?size=728x90' style='width:728px; height:90px; border:0px; padding:0; overflow:hidden; background-color: transparent;'></iframe>
       `,
          }}
        />
      </div>
      <div className="flex flex-col justify-center items-center pt-[50px] ">
        <div className=" max-w-lg block">
          <img src={Maitenance} alt="icon" className="w-full h-auto"></img>
        </div>
        <h1 className="text-5xl py-5">Under Maintenance !</h1>
        <p className="text-lg text-base16 max-w-4xl text-center">
          Sorry for the inconvenience but we’re performing some maintenance at
          the moment. If you need to you can always contact us, otherwise we’ll
          be back online shortly!.
        </p>
        {/* <button
        onClick={() => navigate('/')}
        type="button"
        className="text-white bg-base2 hover:bg-base1 border-1 font-medium rounded-xl text-sm px-5 py-2.5 mr-2 mb-2 mt-[30px] w-[145px]"
      >
        Go Back Home
      </button> */}
      </div>
    </div>
  );
};

export default MaitenancePage;
