import React from 'react';
import ContactIcon from 'assets/img/landing-page/contactus.png';
import { useNavigate } from 'react-router-dom';
import JoinCommunity from '../JoinCommunity';
import ScrollDiv from '../ScrollDiv';
const ContactUs = ({ ref4 }) => {
  return (
    <div>
      <div
        className="bg-[#F4F5F6] py-[100px] min-h-[900px] relative"
        ref={ref4}
      >
        <div className="hidden lg:block overflow-hidden">
          <JoinCommunity />
        </div>

        {/* 
        <ScrollDiv
          pageYOffset={800}
          animate="animate__fadeInLeft animate__animated animate__delay-0.7s"
        >
          <div className="w-full lg:w-[544px] mx-auto text-center pb-[64px]">
            <h1 className="text-[48px] text-[#23262F] leading-[58px]">
              Stay in the know on crypto with Nokofaucet
            </h1>
            <p className="text-base16 opacity-70 py-[16px]">
              A creative agency that lead and inspire
            </p>
          </div>
        </ScrollDiv> */}
        <div className="bg-white lg:mx-[350px] rounded-[72px] z-[40]">
          <ScrollDiv
            pageYOffset={3100}
            animate="animate__fadeInUp animate__animated animate__delay-0.7s h-auto"
          >
            <div className="text-center font-bold pt-[40px]">
              <h1 className="text-[60px] opacity-75 text-default">
                Join our <span className="text-[#6133bd] ">Community</span>
              </h1>
              {/* <p className="text-[16px] opacity-75 tracking-wider text-textSubtle">
                Together we can make the PancakeSwap community even stronger
              </p> */}
              <p className="text-[16px] tracking-wider lg:px-[180px] px-[24px] leading-6 opacity-70 text-center">
                We believe that the best faucets are meant to be shared with our
                friends & the entire world. Join to the Nokofaucet for exciting
                announcements and more!
              </p>
            </div>
          </ScrollDiv>

          <div className="flex justify-center">
            {/* <div className="flex justify-center  pt-[60px]">
              <div className="bg-[#593a98] w-[340px] h-[340px] py-[24px] pl-[60px] rounded-[32px] shadow-lg border-[2px] border-[#3a3360] shadow-[#3a3360]">
                <div className="pb-[24px]">
                  <p className="text-white opacity-70">Community Members</p>
                  <p className="text-base8 text-[40px] font-bold">10K +</p>
                </div>
                <div className="pb-[24px]">
                  <p className="text-white opacity-70">Withdrawals</p>
                  <p className="text-base8 text-[40px] font-bold">1.2 BTC +</p>
                </div>
                <div className="pb-[24px]">
                  <p className="text-white opacity-70">Features</p>
                  <p className="text-base8 text-[40px] font-bold">10 +</p>
                </div>
              </div>
            </div> */}

            <div>
              <img src={ContactIcon} alt="Contact" />
            </div>
          </div>
          {/* <div className="text-center pb-[24px] z-[50]">
            <button
              onClick={() => navigate('/auth/login')}
              type="button"
              className=" text-white bg-base2 hover:bg-base1 font-medium rounded-xl text-sm py-2.5 my-2  w-[200px] text-[16px] shadow h-[48px] cursor-zoom-in"
            >
              Join Us
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
