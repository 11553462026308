import React, { useState } from 'react';
import CointItem from './CointItem';
import BunnyFly1 from 'assets/slick/bunny_fly1.png';

const coins = [
  {
    currency: 'bitcoin',
    code: 'BTC',
  },
  {
    currency: 'ethereum',
    code: 'ETH',
  },
  {
    currency: 'tron',
    code: 'TRX',
  },
  {
    currency: 'dogecoin',
    code: 'DOGE',
  },
];
const CryptoPrice = ({ dataCoin }) => {
  const [selected, setSelected] = useState(0);
  return (
    // <ScrollDiv
    //   pageYOffset={100}
    //   animate="animate__fadeInUp animate__animated animate__delay-0.7s h-screen lg:h-auto"
    // >
    <>
      <div className="grid lg:grid-cols-4 grid-cols-1 lg:mx-[100px] 2xl:mx-[200px] bg-[#F4F5F6] py-[16px] items-center rounded-2xl  cursor-pointer">
        {coins.map((item, index) => (
          <CointItem
            item={item}
            key={item.code}
            dataCoin={dataCoin}
            selected={selected === index}
            setSelected={setSelected}
            index={index}
          />
        ))}
      </div>
      {/* <div className="mt-[80px]">
        <div className="text-center pb-[24px] text-[40px] text-[#000000] font-[600] relative opacity-90">
          <span className="text-base4 relative">
            Community
            <div className="absolute bottom-[0px] left-[-120px]  opacity-100">
              <img
                src={BunnyFly1}
                alt=""
                className=" animate-fly lg:w-[150px] w-[100px] "
              />
            </div>
          </span>
          <div className="text-[16px] text-default opacity-75">
            Together we can make the Nokofaucet community even stronger
          </div>
        </div>
      </div> */}
    </>
    // </ScrollDiv>
  );
};

export default CryptoPrice;
