import React from 'react';
import notFoundIcon from 'assets/svg/icons/pages/404.svg';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center pt-[50px]">
      <div className=" max-w-lg block">
        <img src={notFoundIcon} alt="icon" className="w-full h-auto"></img>
      </div>
      <h1 className="text-5xl py-5">Page Not Found</h1>
      <p className="text-lg text-base16">
        Oops! Looks like you followed a bad link. If you think this is a problem
        with us, please tell us.
      </p>
      <button
        onClick={() => navigate('/')}
        type="button"
        className="text-white bg-base2 hover:bg-base1 border-1 font-medium rounded-xl text-sm px-5 py-2.5 mr-2 mb-2 mt-[30px] w-[145px]"
      >
        Go Back Home
      </button>
    </div>
  );
};

export default NotFoundPage;
