import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface IPtcSlice {
    ptcInfo: any;
    viewingPtc: string | null;
    isOpenCapChaPtc: boolean;
    idPtcEdit: string | null;
    idAddViewPtc: string | null;

}

const initialState: IPtcSlice = {
    ptcInfo: null,
    viewingPtc: null || '',
    isOpenCapChaPtc: false,
    idPtcEdit: null,
    idAddViewPtc: null

};

export const ptcSlice = createSlice({
    name: 'ptc',
    initialState,
    reducers: {
        setPtcInfo: (state, action: PayloadAction<any>) => {
            state.ptcInfo = action.payload;
        },
        setViewingPtc: (state, action: PayloadAction<any>) => {
            state.viewingPtc = action.payload;
        },
        clearViewingPtc: (state, action: PayloadAction<any>) => {
            state.viewingPtc = null;
        },
        setOpenCapchaPtc: (state, action: PayloadAction<any>) => {
            state.isOpenCapChaPtc = action.payload;
        },
        setIdEditPtc: (state, action: PayloadAction<any>) => {
            state.idPtcEdit = action.payload;
        },
        setIdAddViewPtc: (state, action: PayloadAction<any>) => {
            state.idAddViewPtc = action.payload;
        },
    },
});

export const { setPtcInfo, setViewingPtc, clearViewingPtc, setOpenCapchaPtc, setIdEditPtc, setIdAddViewPtc } = ptcSlice.actions;

export const ptcSelector = (state: RootState) => state.ptc;

export default ptcSlice.reducer;
