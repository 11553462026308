
// import { MapGlDrawSchema } from 'pages/MapGlDraw/MapGlDraw.type';
import { ChangePasswordSchema, ForgotPasswordFormSchema, LoginFormSchema, NewPasswordSchema, ResetPasswordBody, StatusEnum } from 'interfaces/login.type';
import { sendPostRequest, sendDeleteRequest, sendPatchRequest, sendGetRequest } from '../axios';
import { ENDPOINTS } from '../endpoints';
import { toast } from 'react-toastify';
import CustomAlert from 'components/alert';

export const sendLoginRequest = async (data: LoginFormSchema) => {
  const res = await sendPostRequest(`/auth/login`, {
    ...data,
  });
  if (res.status === 200) {

    if (res?.data?.user?.status === StatusEnum.BANNED) {
      throw new Error('Your account has been banned!')
    }
    else {
      localStorage.setItem('token', res.data.accessToken);
      localStorage.setItem('isAuthenticated', 'true');
      return res.data;
    }
  } else {
    throw new Error(res?.data?.message)
  }
};

export const sendGetCurrentUserRequest = async () => {
  const res = await sendGetRequest(`/auth/me`);
  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error('Failed to fetch!');
  }
}

export const sendSignUpRequest = async (data: LoginFormSchema) => {
  const res = await sendPostRequest(`/user`, {
    ...data,
  });
  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error(res?.data?.message)
  }
};

export const sendForgotPassword = async (data: ForgotPasswordFormSchema) => {
  const res = await sendPostRequest(`/user/forgot-password`, {
    ...data,
  });
  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error('Failed to fetch!');
  }
}

export const sendResetPassword = async (data: ResetPasswordBody) => {
  const res = await sendPatchRequest(`/user/new-password`, {
    ...data,
  });
  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error('Failed to fetch!');
  }
}

export const sendChangePassword = async (data: ChangePasswordSchema) => {
  const res = await sendPatchRequest(`/user/change-password`, {
    ...data,
  });
  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error('Failed to fetch!');
  }
}

export const sendGenerateCapcha = async () => {
  const res = await sendPostRequest("capcha/gen-capcha");
  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error(res?.data?.message)
  }
};

export const sendGenerateAntibot = async () => {
  const res = await sendPostRequest("antibot/gen-antibot");
  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error(res?.data?.message)
  }
};

export const sendGetCapcha = async () => {
  const res = await sendGetRequest(`/capcha`);
  if (res.status === 200) {
    return res.data
  } else {
    throw new Error(res?.data?.message)
  }
}

export const sendVerifyCapcha = async (data: any) => {
  const res = await sendPostRequest(`/capcha/verify-capcha`, {
    ...data,
  });
  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error(res?.data?.message)
  }
};

export const sendGetAntibot = async () => {
  const res = await sendGetRequest(`/antibot/get-antibot`);
  if (res.status === 200) {
    return res.data
  } else {
    throw new Error('Failed to fetch!');
  }
}




