import React from 'react';
import Svg from '../../Svg';
import { SvgProps } from '../../types';

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 220 220" {...props}>
      <g clip-path="url(#clip0_108_86)">
        <path
          d="M110 220C170.751 220 220 170.751 220 110C220 49.2487 170.751 0 110 0C49.2487 0 0 49.2487 0 110C0 170.751 49.2487 220 110 220Z"
          fill="#F7931A"
        />
        <path
          d="M159.424 96.3875C161.583 81.9775 150.603 74.2294 135.602 69.0594L140.47 49.5344L128.59 46.5781L123.846 65.5875C120.725 64.8037 117.521 64.075 114.324 63.3462L119.102 44.2131L107.222 41.25L102.355 60.7681C99.7697 60.1769 97.226 59.5994 94.7647 58.9806L94.7785 58.9187L78.3885 54.8281L75.226 67.5194C75.226 67.5194 84.0466 69.5406 83.861 69.6644C88.6735 70.8675 89.5397 74.0506 89.3954 76.5806L83.8541 98.8212C84.1841 98.9037 84.6104 99.0275 85.0916 99.2131L83.8335 98.9037L76.0647 130.061C75.4735 131.519 73.9816 133.712 70.6129 132.88C70.7366 133.052 61.9779 130.728 61.9779 130.728L56.0791 144.327L71.5479 148.184C74.4216 148.906 77.2404 149.662 80.011 150.37L75.0954 170.115L86.9685 173.071L91.836 153.546C95.081 154.419 98.2297 155.231 101.31 156.001L96.456 175.443L108.336 178.399L113.252 158.696C133.519 162.532 148.754 160.985 155.168 142.656C160.338 127.903 154.914 119.384 144.251 113.836C152.02 112.049 157.863 106.941 159.424 96.3875ZM132.268 134.461C128.603 149.222 103.75 141.24 95.6929 139.239L102.224 113.08C110.282 115.094 136.111 119.075 132.268 134.461ZM135.946 96.1744C132.598 109.601 111.918 102.774 105.215 101.104L111.127 77.385C117.83 79.0556 139.438 82.17 135.946 96.1744Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_108_86">
          <rect width="220" height="220" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
