import React, { Suspense, useMemo, useState, useEffect } from 'react';

import { SiderBarUser } from 'components/sidebar';
import { Outlet, useNavigate } from 'react-router-dom';
import TopHeader from 'components/top-header';
import Spinner from 'components/common/Spinner';
import Scrollbars from 'react-custom-scrollbars';
import { useDetectAdBlock } from 'adblock-detect-react';
import { useMediaQuery } from 'react-responsive';
import PopUpCapcha from 'components/common/PopUp';
import { useSelector } from 'react-redux';
import {
  ptcSelector,
  setIdAddViewPtc,
  setOpenCapchaPtc,
} from 'store/slices/ptcSlice';
import ModalEditPtc from 'components/common/ModalEditPtc';
import ModalAddViewPtc from 'components/common/ModalEditPtc/ModalAddView';
import useInitInfo from 'hooks/initInfo.hook';

const UserLayout = () => {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 640px)' });
  const [isOpen, setIsOpen] = useState(!isMobileScreen);
  const { initInfo } = useInitInfo();
  const onToggle = (e) => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (isMobileScreen) {
      setIsOpen(false);
    }
  }, []);

  const { viewingPtc, isOpenCapChaPtc, idPtcEdit, idAddViewPtc } =
    useSelector(ptcSelector);

  return (
    <Scrollbars style={{ height: 'calc(100vh - 10px)' }} autoHide>
      <div className="bg-2 mt-[70px] min-h-screen z-10 relative">
        <TopHeader onToggle={onToggle} isOpen={isOpen} />
        <SiderBarUser isOpen={isOpen} onToggle={onToggle} initInfo={initInfo} />
        <div
          className={`transition-all duration-500 ease-out transform translate-x-0 translate-y-0 translate-z-0 ${
            isOpen ? 'ml-64 ' : 'lg:ml-16 '
          }`}
        >
          <div className="flex-1 px-[24px] pt-[16px] relative">
            <Suspense fallback={<Spinner />}>
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
      {isOpenCapChaPtc && (
        <div>
          <PopUpCapcha title="Verify Capcha" />
        </div>
      )}
      <ModalEditPtc title="Edit PTC" isOpen={!!idPtcEdit} />
      <ModalAddViewPtc title="Add More View" isOpen={!!idAddViewPtc} />
    </Scrollbars>
  );
};

export default UserLayout;
