import { QUERY_KEY } from 'interfaces/constants';
import { sendGetCurrentUserRequest } from 'network/services/auth';
import { getCurrentHistory } from 'network/services/user';
import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { isValidToken } from 'utils/auth';

const useHistory = () => {
  const isAuth = isValidToken();
  const {
    data: history,
    isLoading,
    refetch,
  } = useQuery([QUERY_KEY.GET_HISTORY], () => getCurrentHistory(), {
    keepPreviousData: false,
    retry: false,
    enabled: isAuth,
    cacheTime: 2,
  });
  const historyData = useMemo(() => {
    return history && history;
  }, [history]);

  return { historyData, isLoading, refetch };
};

export default useHistory;
