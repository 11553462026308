// import { Shopping } from './pages/Shopping';
// import { Service } from './pages/Services';
// import { Reporting } from './pages/Reporting';
import Dashboard from 'pages/dashboard';
import { IRoute } from 'interfaces';

import {
  HomeIcon,
  AccountFilledIcon,
  BunnyCardsIcon,
  FarmIcon,
  CurrencyIcon,
  CardViewIcon,
  CogIcon,
  TimerIcon,
  WalletIcon,
  GroupsIcon,
  Cards,
  SellIcon,
  VoteIcon,
  EarnIcon,
  ChartIcon,
} from 'assets/svg';

export const sidebarNavigation: Array<IRoute> = [
  {
    path: '/admin',
    title: 'Home',
    icon: <HomeIcon />,
  },
  {
    title: 'Users',
    icon: <GroupsIcon />,
    children: [
      {
        path: '/admin/users',
        title: 'List',
      },
      {
        path: '/admin/user-suspence',
        title: 'Suspence User',
      },
      {
        path: '/admin/user-banned',
        title: 'Banned User',
      },
      {
        path: '/admin/user-same-ip',
        title: 'Same IP User',
      },
    ],
    // children,
  },
  {
    path: '/admin/faucet',
    title: 'Faucet',
    icon: <TimerIcon />,
    // children,
  },
  {
    path: '/admin/daily-bonus',
    title: 'Daily Bonus',
    icon: <SellIcon />,
    // children,
  },
  {
    // path: '/admin/short-link',
    title: 'ShortUrl',
    icon: <CardViewIcon />,
    children: [
      {
        path: '/admin/short-link-top',
        title: 'Top',
      },
      {
        path: '/admin/short-link-details',
        title: 'Shortlink Details',
      },
      {
        path: '/admin/short-link',
        title: 'Configs',
      },
    ],
  },
  {
    path: '/admin/tasks',
    title: 'Tasks',
    icon: <Cards />,
  },
  {
    title: 'Payment',
    icon: <WalletIcon />,
    children: [
      {
        path: '/admin/pay-withdraw',
        title: 'Payment',
      },
      {
        path: '/admin/pending-withdraw',
        title: 'Pending Withdraw',
      },
      {
        path: '/admin/done-withdraw',
        title: 'Done Withdraw',
      },
      {
        path: '/admin/rejected-withdraw',
        title: 'Rejected Withdraw',
      },
      {
        path: '/admin/refund-withdraw',
        title: 'Refund Withdraw',
      },
      {
        path: '/admin/payment',
        title: 'Payment Setting',
      },
    ],
  },
  {
    title: 'Ranking',
    icon: <ChartIcon />,
    children: [
      {
        path: '/admin/pay-prize',
        title: 'Payment',
      },
      {
        path: '/admin/pending-prize',
        title: 'Pending Withdraw',
      },
      {
        path: '/admin/done-prize',
        title: 'Done Withdraw',
      },
      // {
      //   path: '/admin/rejected-prize',
      //   title: 'Rejected Withdraw',
      // },
    ],
  },
  {
    title: 'Campaign',
    icon: <VoteIcon />,
    children: [
      {
        path: '/admin/pending-campaign',
        title: 'Pending Campaign',
      },
      {
        path: '/admin/active-campaign',
        title: 'Active Campaign',
      },
      {
        path: '/admin/rejected-campaign',
        title: 'Rejected Campaign',
      },
      {
        path: '/admin/refunded-campaign',
        title: 'Refuned Campaign',
      },
    ],
  },
  {
    title: 'Offerwall',
    icon: <EarnIcon />,
    children: [
      {
        path: '/admin/pending-offerwall',
        title: 'Pending Offerwall',
      },
      {
        path: '/admin/approved-offerwall',
        title: 'Approved Offerwall',
      },
      {
        path: '/admin/rejected-offerwall',
        title: 'Rejected Offerwall',
      },
    ],
  },
  {
    title: 'Settings',
    icon: <CogIcon />,
    children: [
      {
        path: '/admin/general-setting',
        title: 'Gereral Setting',
        content: <Dashboard />,
      },
      {
        path: '/admin/capcha-setting',
        title: 'Capcha Setting',
        content: <Dashboard />,
      },
      {
        path: '/admin/antibot-setting',
        title: 'Antibot Setting',
        content: <Dashboard />,
      },
    ],
  },
];
