import { QUERY_KEY } from 'interfaces/constants';
import { sendGetCurrentUserRequest } from 'network/services/auth';
import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { isValidToken } from 'utils/auth';

const useRole = () => {
  const isAuth = isValidToken();
  const {
    data: currentUser,
    isLoading,
    refetch: refetchRole,
    isError,
  } = useQuery([QUERY_KEY.GET_ROLE], () => sendGetCurrentUserRequest(), {
    // keepPreviousData: true,
    retry: false,
    enabled: !!isAuth,
  });
  const [role, isVerified] = useMemo(() => {
    return [currentUser?.role, currentUser?.isVerified];
  }, [currentUser]);

  return { role, isVerified, isLoading, refetchRole, isError };
};

export default useRole;
