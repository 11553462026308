import * as React from 'react';
import cx from 'classnames';

type InputProps = {
  error?: string;
  className?: string;
  isTextArea?: boolean;
} & React.ComponentProps<'input'>;

export const Input = ({
  error,
  className,
  isTextArea = false,
  ...props
}: InputProps) => {
  return (
    <input
      {...props}
      className={`bg-gray-50 outline-none border border-gray-300 text-gray-900 sm:text-sm  rounded-xl focus:!ring-primary-600 focus:!border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
         ${isTextArea ? 'resize-none h-[300px] overflow-break-normal ' : ''} ${
        props.disabled ? 'cursor-not-allowed bg-7' : ''
      } `}
      autoComplete="off"
    />
  );
};
