import { yupResolver } from '@hookform/resolvers/yup';
import ImageCapcha from 'components/ImageCapcha';
import useCapcha from 'hooks/capcha.hook';
import { PtcSchema, PtcSchemaAddMoreView, PtcSchemaEditOwn } from 'interfaces';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ErrorText, InputControl } from '../forms';
import CustomSelect from '../forms/DropDownCustom';
import {
  QUERY_KEY,
  durationOptions,
  typeOptionsPtc,
} from 'interfaces/constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { sendVerifyCapcha } from 'network/services/auth';
import { useAppDispatch } from 'store/hooks';
import { toast } from 'react-toastify';
import CustomAlert from 'components/alert';
import {
  sendAddMoreViewPtc,
  sendGetPtcOwnById,
  sendUpdatePtcOwn,
} from 'network/services/user';
import { useSelector } from 'react-redux';
import {
  ptcSelector,
  setIdAddViewPtc,
  setIdEditPtc,
} from 'store/slices/ptcSlice';

const ModalAddViewPtc = ({ title, isOpen }) => {
  const { capcha, refetch: refetchCapcha } = useCapcha();
  const [selectedX, setSelectedX] = useState(-1);
  const [selectedY, setSelectedY] = useState(-1);
  const [durationValue, setDurationValue] = useState(5);
  const [typeValue, setTypeValue] = useState('All Device');
  const dispatch = useAppDispatch();
  const dataRef = useRef(null as any);
  const { idAddViewPtc } = useSelector(ptcSelector);
  const queryClient = useQueryClient();

  const ptcValidationSchema = yup
    .object({
      amount_view: yup.number().required('Amount is required !'),
    })
    .required();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm<PtcSchemaAddMoreView>({
    defaultValues: {
      amount_view: 0,
    },
    resolver: yupResolver(ptcValidationSchema),
  });
  const handleClose = () => {
    dispatch(setIdAddViewPtc(null));
  };

  const handleChangeField = (e: any, fieldName: any) => {
    setValue(fieldName, e.target.value);
  };

  const {
    data: PtcById,
    isLoading,
    refetch,
  } = useQuery(
    [QUERY_KEY.GET_PTC_OWN_BY_ID, idAddViewPtc],
    () => sendGetPtcOwnById(idAddViewPtc || ''),
    {
      keepPreviousData: false,
      retry: false,
      cacheTime: 2,
      enabled: !!idAddViewPtc,
    }
  );

  const handleAddView = useMutation(
    (data: any) => sendAddMoreViewPtc(idAddViewPtc || '', data),
    {
      onSuccess: (metaData) => {
        toast(<CustomAlert type="Success" content="Add successed" />, {
          toastId: 1,
        });
        queryClient.invalidateQueries(QUERY_KEY.GET_CAMPAIGN_PAGINATION);
        handleClose();
        refetchCapcha();
        reset();
      },
      onError: (error: any) => {
        toast(<CustomAlert type="Warning" content="Add failed!" />, {
          toastId: 1,
        });
        handleClose();
        refetchCapcha();
        reset();
      },
    }
  );

  const handleVerifyCapcha = useMutation(
    (data: any) => sendVerifyCapcha(data),
    {
      onSuccess: (metaData) => {
        setSelectedX(-1);
        setSelectedY(-1);
        // setIsOpen(false);
        handleAddView.mutate(dataRef?.current);
        // handleLoginAccount.mutate(dataLogin);
      },
      onError: (error: any) => {
        refetchCapcha();
        setSelectedX(-1);
        setSelectedY(-1);

        toast(<CustomAlert type="Warning" content="Capcha failed!" />, {
          toastId: 1,
        });
      },
    }
  );
  const handleSubmitForm: SubmitHandler<PtcSchemaAddMoreView> = (data) => {
    const capChabody = {
      captchaId: capcha?._id,
      selectedX,
      selectedY,
    };

    handleVerifyCapcha.mutate(capChabody);
    dataRef.current = data;
  };

  const costPerview = useMemo(() => {
    // const duration = getValues('duration');
    if (!PtcById?.duration) return 0;
    switch (PtcById?.duration) {
      case 5:
        return 30;
      case 15:
        return 60;
      case 30:
        return 120;
      case 60:
        return 240;
      default:
        return 0;
    }
  }, [PtcById?.duration]);

  const totalCost = useMemo(() => {
    const total_view = getValues('amount_view');
    if (!total_view) return 0;
    return total_view * costPerview;
  }, [costPerview, getValues, watch('amount_view')]);

  return (
    <div>
      {isOpen && (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full  bg-gray-800 bg-opacity-50 z-50 h-screen">
          <div className="bg-white p-4 rounded-2xl shadow-lg">
            {/* Pop-up content */}
            <h2 className="text-xl font-bold mb-4">{title}</h2>

            <div>
              <h3>
                Title: <span className="text-base5">{PtcById?.title}</span>{' '}
              </h3>
              <h3>
                Amount:{' '}
                <span className="text-base5">
                  {PtcById?.remainView} / {PtcById?.total_view}
                </span>
              </h3>
            </div>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <div className="grid grid-cols-1">
                <div>
                  <Controller
                    name="amount_view"
                    control={control}
                    render={({ field }) => (
                      <>
                        <InputControl
                          type="text"
                          label="Amount"
                          placeholder="Type your Amount"
                          {...field}
                          onChange={(e) => handleChangeField(e, 'amount_view')}
                        />
                        {errors.amount_view && (
                          <ErrorText>{errors.amount_view.message}</ErrorText>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>

              <div className="text-center">
                <ImageCapcha
                  capcha={capcha}
                  setSelectedX={setSelectedX}
                  setSelectedY={setSelectedY}
                  selectedX={selectedX}
                  selectedY={selectedY}
                />
              </div>

              <div>
                Total cost: <span className="text-base5">{totalCost}</span>
              </div>
              <div className="flex gap-2 justify-end">
                <button
                  type="submit"
                  className="mt-4 bg-base2 hover:bg-base3 text-white font-bold py-2 px-4 rounded"
                  // onClick={handleSubmit}
                >
                  Submit
                </button>
                <button
                  className="mt-4 bg-base5 hover:base6 text-white font-bold py-2 px-4 rounded"
                  // onClick={togglePopUp}
                  onClick={handleClose}
                >
                  Cancle
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalAddViewPtc;
