import React, { useState, useEffect } from 'react';

const ScrollDiv = ({
  children,
  pageYOffset = 100,
  animate = 'animate-fadeup',
}) => {
  const [showDiv, setShowDiv] = useState(false);
  console.log(window.pageYOffset);
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > pageYOffset) {
        setShowDiv(true);
      } else {
        setShowDiv(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`${showDiv ? animate : 'invisible'}`}>
      <div>{children}</div>
    </div>
  );
};

export default ScrollDiv;
