import React, { useRef, useState } from 'react';
import { ErrorText, InputControl } from 'components/common/forms';
import { LoginFormSchema } from 'interfaces/login.type';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { sendLoginRequest, sendVerifyCapcha } from 'network/services/auth';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import CustomAlert from 'components/alert';
import HelmetComponent from 'components/common/helmet';
import useRole from 'hooks/role.hook';
import useCapcha from 'hooks/capcha.hook';
import ImageCapcha from 'components/ImageCapcha';
import CoinzillaCommon from 'components/common/Coinzilla/CoinzillaCommon';
import useFingerprint from 'hooks/useBrowserFingerpint';

const Login = () => {
  const navigate = useNavigate();
  const { refetchRole, isVerified: isVerifiedUser } = useRole();
  const { capcha, refetch: refetchCapcha } = useCapcha();
  const [selectedX, setSelectedX] = useState(-1);
  const [selectedY, setSelectedY] = useState(-1);
  const { fingerprint, isLoading } = useFingerprint();

  const loginDataRef = useRef(null as any);

  const loginValidationSchema = yup
    .object({
      email: yup
        .string()
        .email('Invalid email format')
        .required('Email is required'),
      password: yup.string().required('Password is required'),
    })
    .required();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<LoginFormSchema>({
    defaultValues: {
      email: '',
      password: '',
      hash: '',
    },
    resolver: yupResolver(loginValidationSchema),
  });

  const handleChangeField = (e: any, fieldName: any) => {
    setValue(fieldName, e.target.value.trim());
  };

  const handleLoginAccount = useMutation(
    (loginData: LoginFormSchema) => sendLoginRequest(loginData),
    {
      onSuccess: (metaData) => {
        refetchRole();
        toast(<CustomAlert type="Success" content="Login successed" />, {
          toastId: 1,
        });
      },
      onError: (error: any) => {
        toast(<CustomAlert type="Error" content={error.message} />, {
          toastId: 1,
        });
      },
    }
  );

  const handleVerifyCapcha = useMutation(
    (data: any) => sendVerifyCapcha(data),
    {
      onSuccess: (metaData) => {
        if (loginDataRef.current) {
          handleLoginAccount.mutate(loginDataRef.current);
        }
        // handleLoginAccount.mutate(dataLogin);
      },
      onError: (error: any) => {
        refetchCapcha();
        setSelectedX(-1);
        setSelectedY(-1);

        toast(<CustomAlert type="Warning" content="Capcha failed!" />, {
          toastId: 1,
        });
      },
    }
  );

  const handleSubmitForm: SubmitHandler<LoginFormSchema> = (data) => {
    const capChabody = {
      captchaId: capcha?._id,
      selectedX,
      selectedY,
    };

    handleVerifyCapcha.mutate(capChabody);
    const loginData = {
      email: data.email.toLowerCase(),
      password: data.password,
      hash: fingerprint,
    };

    loginDataRef.current = loginData;
  };

  // useEffect(() => {
  //   if (isAuth && userRole === 'admin') {
  //     navigate('/admin');
  //   }

  //   if (isAuth && userRole === 'user') navigate('/user');
  // }, [isAuth, navigate, userRole]);
  // if (isAuth && userRole === 'admin') {
  //   navigate('/admin');
  //   return <></>;
  // }

  return (
    <>
      <HelmetComponent title="Login | Noko Faucet" />
      <section className=" bg-white dark:bg-gradient-to-r from-mixed-1 to-mixed-2">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white space-x-1">
            {/* <LogoIcon /> */}
            {/* <div>NokoFaucet</div> */}
          </div>
          <div className="w-full bg-2 rounded-2xl dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Sign in to your account
              </h1>
              <form
                className="space-y-4 md:space-y-6"
                onSubmit={handleSubmit(handleSubmitForm)}
              >
                <div>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <>
                        <InputControl
                          label="Your email"
                          placeholder="Email"
                          {...field}
                          onChange={(e) => handleChangeField(e, 'email')}
                        />
                        {errors.email && (
                          <ErrorText>{errors.email.message}</ErrorText>
                        )}
                      </>
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <>
                        <InputControl
                          type="password"
                          label="Your Password"
                          placeholder="Password"
                          {...field}
                          onChange={(e) => handleChangeField(e, 'password')}
                        />
                        {errors.password && (
                          <ErrorText>{errors.password.message}</ErrorText>
                        )}
                      </>
                    )}
                  />
                </div>
                {/* <div className="lg:flex lg:justify-center">
                  {isMobileScreen ? (
                    <span id="ct_cIMQjrypT7Q"></span>
                  ) : (
                    <span id="ct_cOo2Tod0ady"></span>
                  )}
                </div> */}
                <div className="flex items-center justify-center py-3">
                  <div className="flex items-start">
                    <div className="flex items-center h-5 pr-3 my-5 ">
                      {/* <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPCHA_API_KEY}
                        onChange={onChange}
                        hl="en"
                      /> */}
                      {/* <div className="relative">
                        <img
                          src={`data:image/jpeg;base64,${capcha?.image}`}
                          onClick={handleImageClick}
                          alt="capcha"
                        />
                        {selectedX !== -1 && selectedY !== -1 && (
                          <div
                            style={{
                              position: 'absolute',
                              top: selectedY,
                              left: selectedX,
                              width: 8,
                              height: 8,
                              background: '#D130AA',
                              borderRadius: '50%',
                            }}
                          />
                        )}
                      </div> */}

                      <ImageCapcha
                        capcha={capcha}
                        setSelectedX={setSelectedX}
                        setSelectedY={setSelectedY}
                        selectedX={selectedX}
                        selectedY={selectedY}
                        // isLoading={isLoading}
                      />
                    </div>
                  </div>
                </div>
                {/* <div>
                  <p>Selected X: {selectedX}</p>
                  <p>Selected Y: {selectedY}</p>
                </div> */}
                {/* <div className="flex justify-center ads">
                  <BitmediaAdsCommon type="300" />
                </div> */}
                <div className="flex justify-center ads">
                  <CoinzillaCommon type="300" />
                </div>
                <div>
                  <span
                    onClick={() => navigate('/forgot-password')}
                    className="cursor-pointer text-sm font-normal text-primary-600 hover:underline dark:text-primary-500 pt-[50px]"
                  >
                    Forgot password?
                  </span>
                </div>
                {handleLoginAccount.isLoading ? (
                  <div className="justify-center flex">
                    <svg
                      aria-hidden="true"
                      className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-transparent font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    Sign in
                  </button>
                )}

                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Don’t have an account yet?{' '}
                  <span
                    className="font-bold text-base5 hover:underline dark:text-primary-500 cursor-pointer"
                    onClick={() => navigate('/sign-up')}
                  >
                    Sign up
                  </span>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
