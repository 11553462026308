// import { Shopping } from './pages/Shopping';
// import { Service } from './pages/Services';
// import { Reporting } from './pages/Reporting';
import Dashboard from 'pages/dashboard';
import { IRoute } from 'interfaces';

import {
  HomeIcon,
  TimerIcon,
  CommunityIcon,
  SellIcon,
  WalletIcon,
  Cards,
  TelegramIcon,
  CardViewIcon,
  CogIcon,
  ResourcesFilledIcon,
  ProposalIcon,
  ChartIcon,
  VoteIcon,
  EarnIcon,
} from 'assets/svg';

export const sidebarNavigationUser: Array<IRoute> = [
  {
    path: '/user',
    title: 'Dashboard',
    icon: <HomeIcon />,
  },
  {
    path: '/user/short-link',
    title: 'Short Link',
    icon: <CardViewIcon />,
    isShortLink: true,
    // children,
  },
  {
    path: '/user/offerwall',
    title: 'Offerwall',
    icon: <EarnIcon />,
    isNew: true,
  },
  {
    path: '/user/referral',
    title: 'Referral',
    icon: <CommunityIcon />,
    isGood: true,
    // children,
  },
  {
    path: '/user/tasks',
    title: 'Daily Tasks',
    icon: <Cards />,
  },
  {
    path: '/user/daily-bonus',
    title: 'Daily Bonus',
    icon: <SellIcon />,
  },
  {
    path: '/user/faucet',
    title: 'Faucet',
    icon: <TimerIcon />,
  },
  {
    path: '/user/ptc',
    title: 'Paid To Click',
    icon: <VoteIcon />,
    isPtc: true,
    // children,
  },
  {
    path: '/user/ranking',
    title: 'Ranking',
    icon: <ChartIcon />,
    // isNew: true,
    // children,
  },
  {
    title: 'Payment',
    icon: <WalletIcon />,
    children: [
      {
        path: '/user/withdraw',
        title: 'Withdraw',
      },
      {
        path: '/user/history',
        title: 'History',
      },
    ],
  },
  {
    title: 'Advertise',
    icon: <ProposalIcon />,
    children: [
      {
        path: '/user/deposit',
        title: 'Deposit',
      },
      {
        path: '/user/exchange',
        title: 'Exchange',
      },
      {
        path: '/user/campaign',
        title: 'Campaign',
      },
      {
        path: '/user/manage-campaign',
        title: 'Manage Campaign',
      },
    ],
  },
  {
    path: process.env.REACT_APP_FANPAGE,
    title: 'Fanpage',
    icon: <ResourcesFilledIcon />,
    isLink: true,
  },
  {
    path: '/user/account-settings',
    title: 'Account',
    icon: <CogIcon />,
  },
];
