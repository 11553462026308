import {
  ArrowDownIcon,
  ArrowDropDownIcon,
  BitcoinIcon,
  ChevronDownIcon,
  DogeIcon,
  EthereumIcon,
  LogoRoundIcon,
  TrxIcon,
  UsdtIcon,
} from 'assets/svg';
import { Label } from 'components/common/forms/Label';
import useProfile from 'hooks/useProfile';
import { useEffect, useMemo, useState } from 'react';
import { BigNumber } from 'bignumber.js';
import useGeneralSettings from 'hooks/generalSetting.hook';

interface ICustomSelectProps {
  profile?: any;
  currencies?: any;
}

const currenciesOptions = [
  {
    id: 0,
    label: 'Tokens',
    value: 'Tokens',
    icon: <LogoRoundIcon />,
  },
  {
    id: 1,
    label: 'BTC',
    value: 'BTC',
    icon: <BitcoinIcon />,
  },
  {
    id: 2,
    label: 'USDT',
    value: 'USDT',
    icon: <UsdtIcon />,
  },
  {
    id: 3,
    label: 'ETH',
    value: 'ETH',
    icon: <EthereumIcon />,
  },
  {
    id: 4,
    label: 'TRX',
    value: 'TRX',
    icon: <TrxIcon />,
  },
  {
    id: 5,
    label: 'DOGE',
    value: 'DOGE',
    icon: <DogeIcon />,
  },
];
const CurrencySwitch = ({ profile, currencies }: ICustomSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    currenciesOptions[0] as any
  );

  const { generalSettings } = useGeneralSettings();

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const balance = useMemo(() => {
    if (selectedOption?.value === 'Tokens') {
      return profile?.balance;
    } else {
      const currentSelected = currencies?.find(
        (item) => item.code === selectedOption?.value
      );
      const price = currentSelected?.price;
      const result =
        (profile?.balance * generalSettings?.token_rate || 0.00001) / price ||
        0; // 0.00001 is the price of 1 token
      return new BigNumber(result).toFixed(8);
    }
  }, [
    currencies,
    generalSettings?.token_rate,
    profile?.balance,
    selectedOption?.value,
  ]);

  return (
    <div className=" w-[full]">
      <div
        className="relative cursor-pointer select-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="rounded-xl shadow border flex bg-gray-50">
          <div className="flex items-center justify-between h-[25px] px-[10px] text-default sm:text-sm space-x-1">
            <div className="px-1 truncate max-w-[125px]">{balance || 0}</div>
            <span> {selectedOption?.label}</span>
            <span> {selectedOption?.icon}</span>
            <div className="inset-y-0 right-0 flex items-center pointer-events-none">
              <ChevronDownIcon className="w-5 h-5 text-gray-400" />
            </div>
          </div>
        </div>

        {isOpen && (
          <ul className="absolute z-10 w-full bg-white rounded-md shadow-md mt-1 py-1 ">
            {currenciesOptions.length > 0 ? (
              currenciesOptions.map((option) => (
                <li
                  key={option?.value}
                  className={`px-3 py-2 hover:bg-gray-100 cursor-pointer text-[14px] ${
                    option?.value === selectedOption?.value ? 'bg-gray-100' : ''
                  }`}
                  onClick={() => handleSelect(option)}
                >
                  <div className="flex space-x-2">
                    <div>{option?.icon}</div>
                    <div>{option?.label}</div>
                  </div>
                </li>
              ))
            ) : (
              <li className=" className=`px-3 py-2 hover:bg-gray-100 cursor-pointer text-[14px] text-control flex justify-center">
                No options
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CurrencySwitch;
