import React from 'react';
import Skeleton from 'components/common/Skeleton';

interface ImageCapchaProps {
  capcha: any;
  setSelectedX: (value: number) => void;
  setSelectedY: (value: number) => void;
  selectedX: number;
  selectedY: number;
  isLoading?: boolean;
}
const ImageCapcha = ({
  capcha,
  setSelectedX,
  setSelectedY,
  selectedX,
  selectedY,
  isLoading,
}: ImageCapchaProps) => {
  const handleImageClick = (event) => {
    // Lấy tọa độ của hình ảnh trong phần tử <img> so với trang
    const imageX = event.target.getBoundingClientRect().left;
    const imageY = event.target.getBoundingClientRect().top;

    // Lấy tọa độ của điểm được chọn trong hình ảnh
    const clickX = event.clientX - imageX;
    const clickY = event.clientY - imageY;

    // Lưu tọa độ đã chọn
    setSelectedX(clickX);
    setSelectedY(clickY);
  };
  return !capcha?.image || isLoading ? (
    <>
      <Skeleton />
    </>
  ) : (
    <div className="relative">
      {/* <div className="text-[12px] absolute top-5 text-textSubtle cursor-pointer px-[16px]">
        Select the upside down picture.{' '}
      </div> */}
      <div className="text-[12px] absolute top-5 text-textSubtle cursor-pointer px-[16px] left-[50px]">
        Select the upside down picture.{' '}
      </div>
      <img
        src={`data:image/jpeg;base64,${capcha?.image}`}
        onClick={handleImageClick}
        alt="capcha"
      />

      {selectedX !== -1 && selectedY !== -1 && (
        <div
          style={{
            position: 'absolute',
            top: selectedY,
            left: selectedX,
            width: 8,
            height: 8,
            background: '#D130AA',
            borderRadius: '50%',
          }}
        />
      )}
    </div>
  );
};

export default ImageCapcha;
