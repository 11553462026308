import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface ITaskSlice {
    taskId: string | null;
}

const initialState: ITaskSlice = {
    taskId: null,
};

export const taskSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        setTaskId: (state, action: PayloadAction<any>) => {
            state.taskId = action.payload;
        }
    },
});

export const { setTaskId } = taskSlice.actions;

export const userSelector = (state: RootState) => state.user;

export default taskSlice.reducer;
