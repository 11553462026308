import * as React from 'react';
import avatar from '../../assets/img/avatar.png';
import { Link, useLocation } from 'react-router-dom';
import { IRoute } from '../../interfaces';
import SubMenu from 'components/sub-menu';
import { sidebarNavigation } from 'components/layout/sider/SiderMenu';
import Scrollbars from 'react-custom-scrollbars';
import { useMediaQuery } from 'react-responsive';

interface IRouteProps {
  routers?: IRoute[];
  isOpen?: boolean;
  onToggle?: (e) => void;
}

const sidebarNavFlat = sidebarNavigation.reduce(
  (result: any, current) =>
    result.concat(
      current.children && current.children.length > 0
        ? current.children
        : current
    ),
  []
);

const SideBar = ({ isOpen, onToggle }: IRouteProps) => {
  const location = useLocation();
  const isMobileScreen = useMediaQuery({ query: '(max-width: 640px)' });

  const currentMenuItem = sidebarNavFlat.find(
    ({ path }) => path === location.pathname
  );

  return (
    <aside
      id="logo-sidebar"
      className={`fixed top-0 left-0 z-40 h-screen pt-18 bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-dark dark:border-gray-700 ${
        isOpen
          ? isMobileScreen
            ? 'w-full'
            : 'w-64 transition-all duration-500 ease-out transform translate-x-0 translate-y-0 translate-z-0 '
          : 'lg:w-16 w-0 transition-all duration-500 ease-out transform translate-x-0 translate-y-0 translate-z-0 '
      }`}
      aria-label="Sidebar"
    >
      <div className="h-full pb-4 overflow-y-auto bg-white dark:bg-dark">
        <div id="sideBar" className="pb-4 text-gray">
          <div className="flex flex-col h-full space-y-10">
            <SideBarMenu
              isOpen={isOpen}
              onToggle={onToggle}
              routers={sidebarNavigation}
            />

            {/* <SideBarBottom /> */}
          </div>
        </div>
      </div>
    </aside>
  );
};

type SideBarMenuProps = {
  routers: IRoute[];
  isOpen?: boolean;
  onToggle?: (e) => void;
};

const SideBarMenu = ({ routers, isOpen, onToggle }: SideBarMenuProps) => {
  const location = useLocation();
  const isMobileScreen = useMediaQuery({ query: '(max-width: 640px)' });

  return (
    <div className="flex-1 ">
      <Scrollbars style={{ height: 'calc(100vh - 130px)' }} autoHide>
        {sidebarNavigation &&
          sidebarNavigation.map((menuItem: any, idx) =>
            !menuItem.children ? (
              <Link
                to={menuItem?.path}
                key={idx}
                onClick={isMobileScreen ? onToggle : () => {}}
              >
                <div
                  className={`flex text-textMenu font-[16px] space-x-3 hover:bg-gray-100 px-6  h-[48px] w-full items-center cursor-pointer ${
                    menuItem.path === location.pathname
                      ? 'border-l-4 border-[#9A6AFF] pl-5'
                      : 'border-transparent'
                  }`}
                >
                  <div>{menuItem?.icon}</div>
                  <div
                    className={`truncate overflow-hidden h-full flex items-center ${
                      isOpen ? 'block' : 'hidden'
                    }`}
                  >
                    {menuItem.title}
                  </div>
                </div>
              </Link>
            ) : (
              <SubMenu
                menuItem={menuItem}
                isOpen={isOpen}
                onToggle={onToggle}
                location={location}
              />
            )
          )}
      </Scrollbars>
    </div>
  );
};

const SideBarBottom = () => {
  return (
    <div className="flex flex-col space-y-4">
      {/* <div className="cursor-pointer h-6 w-6 mx-auto">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
      </div> */}
      {/* <div className="cursor-pointer h-6 w-6 mx-auto">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
      </div> */}
      <img
        className="w-10 h-10 rounded-full"
        src={avatar}
        alt="Rounded avatar"
      ></img>
    </div>
  );
};

export default SideBar;
