import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface IRankingSlice {
    faucetRanking: any[];
    ptcRanking: any[];
    offerwallRanking: any[];
    referralRanking: any[];
    shortlinkRanking: any[];
    referralCount: number;
    shortlinkCount: number;
    ptcCount: number;
    offerwallCount: number;
    faucetCount: number;
}

const initialState: IRankingSlice = {
    faucetRanking: [],
    ptcRanking: [],
    offerwallRanking: [],
    referralRanking: [],
    shortlinkRanking: [],
    referralCount: 0,
    shortlinkCount: 0,
    ptcCount: 0,
    offerwallCount: 0,
    faucetCount: 0,
};

export const rankingSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        setFaucetRanking: (state, action: PayloadAction<any>) => {
            state.faucetRanking = action.payload;
        },
        setPtcRanking: (state, action: PayloadAction<any>) => {
            state.ptcRanking = action.payload;
        },
        setOfferwallRanking: (state, action: PayloadAction<any>) => {
            state.offerwallRanking = action.payload;
        },
        setReferralRanking: (state, action: PayloadAction<any>) => {
            state.referralRanking = action.payload;
        },
        setShortlinkRanking: (state, action: PayloadAction<any>) => {
            state.shortlinkRanking = action.payload;
        },
        setReferralCount: (state, action: PayloadAction<number>) => {
            state.referralCount = action.payload;
        },
        setShortlinkCount: (state, action: PayloadAction<number>) => {
            state.shortlinkCount = action.payload;
        },
        setPtcCount: (state, action: PayloadAction<number>) => {
            state.ptcCount = action.payload;
        },
        setOfferwallCount: (state, action: PayloadAction<number>) => {
            state.offerwallCount = action.payload;
        },
        setFaucetCount: (state, action: PayloadAction<number>) => {
            state.faucetCount = action.payload;
        }
    },
});

export const {
    setFaucetRanking,
    setPtcRanking,
    setOfferwallRanking,
    setReferralRanking,
    setShortlinkRanking,
    setReferralCount,
    setShortlinkCount,
    setPtcCount,
    setOfferwallCount,
    setFaucetCount
} = rankingSlice.actions;

export const rankingSelector = (state: RootState) => state.ranking;

export default rankingSlice.reducer;
