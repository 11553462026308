import ImageCapcha from 'components/ImageCapcha';
import CustomAlert from 'components/alert';
import useCapcha from 'hooks/capcha.hook';
import useProfile from 'hooks/useProfile';
import { QUERY_KEY } from 'interfaces/constants';
import { sendVerifyCapcha } from 'network/services/auth';
import { sendClaimPtc } from 'network/services/user';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store/hooks';
import {
  clearViewingPtc,
  ptcSelector,
  setOpenCapchaPtc,
} from 'store/slices/ptcSlice';
import CoinzillaCommon from '../Coinzilla/CoinzillaCommon';

const PopUpCapcha = ({ title }) => {
  //   const [isOpen, setIsOpen] = useState(false);
  const { capcha, refetch: refetchCapcha } = useCapcha();
  const { viewingPtc, isOpenCapChaPtc } = useSelector(ptcSelector);

  const [selectedX, setSelectedX] = useState(-1);
  const [selectedY, setSelectedY] = useState(-1);
  const dispatch = useAppDispatch();
  const { profile, refetch } = useProfile();
  const queryClient = useQueryClient();
  const togglePopUp = () => {
    // setIsOpen(!isOpen);
    dispatch(setOpenCapchaPtc(!isOpenCapChaPtc));
    dispatch(clearViewingPtc(null));
  };
  const handleClaimPtc = useMutation((id: string) => sendClaimPtc(id), {
    onSuccess: (metaData) => {
      toast(<CustomAlert type="Success" content="Claim successed" />, {
        toastId: 1,
      });
      dispatch(clearViewingPtc(null));
      // refectData();
      queryClient.refetchQueries(QUERY_KEY.GET_CAMPAIGN_FOR_USER);

      refetch();
    },
    onError: (error: any) => {
      toast(<CustomAlert type="Warning" content="Claim failed!" />, {
        toastId: 1,
      });
      dispatch(clearViewingPtc(null));
    },
  });

  const handleVerifyCapcha = useMutation(
    (data: any) => sendVerifyCapcha(data),
    {
      onSuccess: (metaData) => {
        setSelectedX(-1);
        setSelectedY(-1);
        // setIsOpen(false);
        dispatch(setOpenCapchaPtc(false));
        handleClaimPtc.mutate(viewingPtc || '');
        // handleLoginAccount.mutate(dataLogin);
      },
      onError: (error: any) => {
        refetchCapcha();
        setSelectedX(-1);
        setSelectedY(-1);

        toast(<CustomAlert type="Warning" content="Capcha failed!" />, {
          toastId: 1,
        });
      },
    }
  );

  const handleSubmit = () => {
    const capChabody = {
      captchaId: capcha?._id,
      selectedX,
      selectedY,
    };
    handleVerifyCapcha.mutate(capChabody);
  };

  return (
    <div>
      {isOpenCapChaPtc && (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full  bg-gray-800 bg-opacity-50 z-50 h-screen">
          <div className="bg-white p-4 rounded shadow-lg">
            {/* Pop-up content */}
            <h2 className="text-xl font-bold mb-4">{title}</h2>
            <div className="text-center">
              <ImageCapcha
                capcha={capcha}
                setSelectedX={setSelectedX}
                setSelectedY={setSelectedY}
                selectedX={selectedX}
                selectedY={selectedY}
              />
              <CoinzillaCommon type="300" />
            </div>
            <div className="flex gap-2 justify-end">
              <button
                className="mt-4 bg-base2 hover:bg-base3 text-white font-bold py-2 px-4 rounded"
                onClick={handleSubmit}
              >
                Verify
              </button>
              <button
                className="mt-4 bg-base5 hover:base6 text-white font-bold py-2 px-4 rounded"
                onClick={togglePopUp}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopUpCapcha;
