import React from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps {
  amount?: string;
  total?: string;
  title?: string;
  customClass?: string;
  link?: string;
  prefix?: string;
  surfix?: string;
  image?: any;
}
const CardInfoItem = ({
  amount,
  total,
  title,
  customClass,
  link,
  prefix = '$',
  surfix = '',
  image,
}: IProps) => {
  const navigate = useNavigate();
  return (
    <div className="lg:col-span-3 col-span-9">
      <div className="grid lg:grid-cols-1 grid-cols-1 gap-3 items-center">
        <div
          className={`min-h-[175px] p-5  border rounded-xl shadow dark:bg-gray-800 dark:border-gray-700 h-full ${customClass}`}
        >
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-1">
              {image && <img src={image} alt="" width={40} />}

              <div className="text-[18px] text-[#111827] text-ellipsis font-bold">
                {title}
              </div>
            </div>
            <div
              className="flex items-center"
              onClick={() => navigate(link || '/admin')}
            >
              <div className=" text-blue-600 text-[14px] cursor-pointer">
                More detail
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center text-[14px] pt-3">
            <div>Amount :</div>
            <div className="font-bold text-[20px]">{amount || ''}</div>
          </div>
          <div className="flex justify-between text-[14px] leading-5">
            <div>Total :</div>
            <div className="font-bold text-[20px] text-base5">
              {total && `${prefix}${total} ${surfix}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardInfoItem;
