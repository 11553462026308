import React from 'react';
import { useNavigate } from 'react-router-dom';

const FeaturItem = ({ item }: any) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="lg:grid lg:grid-cols-3 items-start relative gap-[30px]">
        <div className="lg:col-span-1 pl-[20px] lg:pl-0">
          <img src={item?.icon} alt="" className="min-w-[150px] h-auto" />
        </div>
        <div className="px-[28px] lg:col-span-2 items-end">
          <div className="text-[#23262F] text-[24px] font-bold lg:pt-0 pt-[20px]">
            {item?.title}
          </div>
          <div className="leading-[28px] text-[15px] tracking-wider">
            {item?.des}
          </div>
          <div
            className="leading-[28px]  opacity-75 text-[15px] absolute lg:bottom-0 bottom-[-32px] text-[#1FD7C4] cursor-pointer font-[600]"
            onClick={() => navigate('/auth/login')}
          >
            Join Now
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturItem;
