import { QUERY_KEY } from 'interfaces/constants';
import { sendGetCurrentUserRequest } from 'network/services/auth';
import { sendGetAllCurrencies } from 'network/services/user';
import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { isValidToken } from 'utils/auth';

const useCurrencies = () => {
  const isAuth = isValidToken();
  const { data, isLoading, refetch } = useQuery(
    [QUERY_KEY.GET_CURRENCIES],
    () => sendGetAllCurrencies(),
    {
      keepPreviousData: false,
      retry: false,
      enabled: isAuth,
      cacheTime: 2,
    }
  );
  const currencies = useMemo(() => {
    return data;
  }, [data]);

  return { currencies, isLoading, refetch };
};

export default useCurrencies;
