import * as React from 'react';
import avatar from '../../assets/img/avatar.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IRoute } from '../../interfaces';
import SubMenu from 'components/sub-menu';
import { sidebarNavigationUser } from 'components/layout/sider/SiderMenuUser';
import {
  LogoutIcon,
  MoonIcon,
  PancakeRoundIcon,
  PoolIcon,
  SunIcon,
  TelegramIcon,
  TwitterIcon,
} from 'assets/svg';
import Scrollbars from 'react-custom-scrollbars';
import useProfile from 'hooks/useProfile';
import BigNumber from 'bignumber.js';
import { useMediaQuery } from 'react-responsive';
import { QueryClient, useQueryClient } from 'react-query';
import { QUERY_KEY } from 'interfaces/constants';
import useGeneralSettings from 'hooks/generalSetting.hook';

interface IRouteProps {
  routers?: IRoute[];
  isOpen?: boolean;
  onToggle?: (e) => void;
  initInfo?: any;
}

const sidebarNavFlat = sidebarNavigationUser.reduce(
  (result: any, current) =>
    result.concat(
      current.children && current.children.length > 0
        ? current.children
        : current
    ),
  []
);

const SideBarUser = ({ isOpen, onToggle, initInfo }: IRouteProps) => {
  const location = useLocation();
  const isMobileScreen = useMediaQuery({ query: '(max-width: 640px)' });

  const currentMenuItem = sidebarNavFlat.find(
    ({ path }) => path === location.pathname
  );

  return (
    <aside
      id="logo-sidebar"
      className={`fixed top-0 left-0 z-40 h-screen pt-18 bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-dark dark:border-gray-700 ${
        isOpen
          ? isMobileScreen
            ? 'w-full'
            : 'w-64 transition-all duration-500 ease-out transform translate-x-0 translate-y-0 translate-z-0 '
          : 'lg:w-16 w-0 transition-all duration-500 ease-out transform translate-x-0 translate-y-0 translate-z-0 '
      }`}
      aria-label="Sidebar"
    >
      <div className="h-full pb-4 overflow-y-auto bg-white dark:bg-dark">
        <div id="sideBar" className="pb-4 text-gray">
          <div className="flex flex-col h-full space-y-10">
            <SideBarMenu
              isOpen={isOpen}
              onToggle={onToggle}
              routers={sidebarNavigationUser}
              initInfo={initInfo}
            />

            {/* <SideBarBottom /> */}
          </div>
        </div>
      </div>
    </aside>
  );
};

type SideBarMenuProps = {
  routers: IRoute[];
  isOpen?: boolean;
  onToggle?: (e) => void;
  initInfo?: any;
};

const SideBarMenu = ({
  routers,
  isOpen,
  onToggle,
  initInfo,
}: SideBarMenuProps) => {
  const location = useLocation();
  const isMobileScreen = useMediaQuery({ query: '(max-width: 640px)' });

  return (
    <div className="flex-1 ">
      <Scrollbars style={{ height: 'calc(100vh - 246px)' }} autoHide>
        {sidebarNavigationUser &&
          sidebarNavigationUser.map((menuItem: any, idx) =>
            !menuItem.children ? (
              <Link
                to={menuItem?.path}
                key={idx}
                target={menuItem.isLink ? '_blank' : ''}
                onClick={isMobileScreen ? onToggle : () => {}}
                // onClick={updateIsHideContent}
              >
                <div
                  className={`flex text-textMenu font-[16px] space-x-3 hover:bg-gray-100 px-6 h-[48px] w-full items-center cursor-pointer ${
                    menuItem.path === location.pathname
                      ? 'border-l-4 border-[#9A6AFF] pl-5'
                      : 'border-transparent'
                  }`}
                >
                  <div>{menuItem?.icon}</div>
                  <div
                    className={`truncate overflow-hidden h-full flex items-center ${
                      isOpen ? 'block' : 'hidden'
                    } `}
                  >
                    {menuItem.title}{' '}
                    {menuItem?.isNew ? (
                      <div className="rounded-lg absolute right-[50%] lg:right-5 w-[40px] flex justify-center ">
                        <span className="text-base5 px-3 font-bold">New</span>
                      </div>
                    ) : null}
                    {menuItem?.isGood ? (
                      <div className="rounded-lg absolute right-[50%] lg:right-5 w-[40px] flex justify-center ">
                        <span className="text-base5 px-3 font-bold">Good</span>
                      </div>
                    ) : null}
                    {menuItem?.isShortLink ? (
                      <div className="bg-base2 rounded-lg absolute right-[50%] lg:right-5 w-[40px] flex justify-center">
                        <span className="text-white px-2 font-medium text-[14px]">
                          {initInfo?.short_link_count}
                        </span>
                      </div>
                    ) : null}
                    {menuItem?.isPtc ? (
                      <div className="bg-base2 rounded-lg absolute right-[50%] lg:right-5 flex justify-center w-[40px]">
                        <span className="text-white px-2 font-medium text-[14px]">
                          {initInfo?.ptc_count}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Link>
            ) : (
              <SubMenu
                key={idx}
                menuItem={menuItem}
                isOpen={isOpen}
                onToggle={onToggle}
                location={location}
              />
            )
          )}
      </Scrollbars>
      {isOpen && <SideBarBottom />}
    </div>
  );
};

const SideBarBottom = () => {
  const queryClient = useQueryClient();

  // const [isDarkMode, setIsDarkMode] = React.useState(false);
  // function handleToggle() {
  //   setIsDarkMode((prevState) => !prevState);
  // }
  const navigate = useNavigate();
  const { profile } = useProfile();
  const { generalSettings } = useGeneralSettings();
  const handleSignOut = () => {
    navigate('/auth/login');
    localStorage.removeItem('token');
    localStorage.removeItem('isAuthenticated');
    queryClient.setQueryData([QUERY_KEY.GET_ROLE], null);
  };
  return (
    <div className="px-[24px] fixed bottom-[80px] border-t-[1px] h-2 py-[8px] w-full z-50">
      <div className="flex gap-5 py-1 items-center justify-between">
        <p className="text-textMenu text-[14px] flex gap-2">
          <PancakeRoundIcon width={14} />
          <span className="text-base5 ">{profile?.balance} </span> tokens
          {/* {new BigNumber(
            profile?.balance * generalSettings?.token_rate
          ).toFixed(5)}{' '}
          $ */}
        </p>

        <div
          onClick={handleSignOut}
          className="flex items-center py-2  focus:outline-none focus:bg-gray-200 cursor-pointer"
        >
          <LogoutIcon />
        </div>

        {/* <Link
          to={process.env.REACT_APP_TELEGRAM_GROUP || ''}
          target="_blank"
          // onClick={updateIsHideContent}
        >
          <TwitterIcon />
        </Link> */}
      </div>
      <p className="text-textMenu text-[14px] flex gap-1 items-end">
        <PoolIcon width={17} />
        Deposit: <span className="text-base8 ">{profile?.deposit} </span>
      </p>
      <div className="py-1 flex"></div>
      {/* <div className="flex gap-5 py-3 items-center justify-between">
        <Link
          to={process.env.REACT_APP_TELEGRAM_GROUP || ''}
          target="_blank"
          // onClick={updateIsHideContent}
        >
          <TelegramIcon />
        </Link>
        <SunIcon />
      </div> */}
    </div>
  );
};

export default SideBarUser;
