import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorText, InputControl } from 'components/common/forms';
import HelmetComponent from 'components/common/helmet';
import { NewPasswordSchema, ResetPasswordBody } from 'interfaces/login.type';
import React, { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomAlert from 'components/alert';
import { useMutation } from 'react-query';
import { sendResetPassword } from 'network/services/auth';
import jwt_decode from 'jwt-decode';

const NewPassword = () => {
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const { token } = useParams();

  const isValidToken = useMemo(() => {
    if (!token) {
      return false;
    }
    const jwt: any = jwt_decode(token);
    return Date.now() / 1000 < jwt.exp;
  }, [token]);

  const newPasswordValidationSchema = yup
    .object({
      password: yup
        .string()
        .max(50, 'Too Long!')
        .min(8, 'Password must be more than 8 characters!')
        .matches(/[a-z]/, 'At least one lowercase char')
        .matches(/[A-Z]/, 'At least one uppercase char')
        .matches(
          /[a-zA-Z]+[^a-zA-Z\s]+/,
          'At least 1 number or special char (@,!,#, etc).'
        )
        .required('Required!'),
      repassword: yup
        .string()
        .oneOf(
          [yup.ref('password')],
          'The two passwords that you entered do not match!'
        ),
    })
    .required();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<NewPasswordSchema>({
    defaultValues: {
      password: '',
      repassword: '',
    },
    resolver: yupResolver(newPasswordValidationSchema),
  });

  const handleChangeField = (e: any, fieldName: any) => {
    setValue(fieldName, e.target.value);
  };

  const onChange = (reponse) => {
    if (reponse) {
      setIsVerified(true);
    }
  };

  const handleResetPassword = useMutation(
    (data: ResetPasswordBody) => sendResetPassword(data),
    {
      onSuccess: (metaData) => {
        toast(<CustomAlert type="Success" content="Successed" />, {
          toastId: 1,
        });
        navigate('/auth/login');
      },
      onError: () => {
        toast(<CustomAlert type="Error" content="Failed" />, {
          toastId: 1,
        });
      },
    }
  );

  const handleSubmitForm: SubmitHandler<NewPasswordSchema> = (data) => {
    if (!isVerified)
      toast(<CustomAlert type="Warning" content="Capcha is required!" />, {
        toastId: 1,
      });
    else {
      const body = {
        password: data.password,
        token: token,
      };

      if (!isValidToken) {
        toast(<CustomAlert type="Session is expried" content="Warning" />, {
          toastId: 1,
        });
        return;
      }
      body && handleResetPassword.mutate(body);
    }
  };

  return (
    <>
      <>
        <HelmetComponent title="Reset Password | Noko Faucet" />
        <section className=" bg-white dark:bg-gradient-to-r from-mixed-1 to-mixed-2">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <div className="w-full bg-2 rounded-2xl dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Reset Password
                </h1>
                <form
                  className="space-y-4 md:space-y-6"
                  onSubmit={handleSubmit(handleSubmitForm)}
                >
                  <div>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <>
                          <InputControl
                            label="New Password"
                            type="password"
                            placeholder="Enter your new password"
                            {...field}
                            onChange={(e) => handleChangeField(e, 'password')}
                          />
                          {errors.password && (
                            <ErrorText>{errors.password.message}</ErrorText>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="repassword"
                      control={control}
                      render={({ field }) => (
                        <>
                          <InputControl
                            label="Repassword"
                            type="password"
                            placeholder="Enter your repassword"
                            {...field}
                            onChange={(e) => handleChangeField(e, 'repassword')}
                          />
                          {errors.repassword && (
                            <ErrorText>{errors.repassword.message}</ErrorText>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-start">
                      <div className="flex items-center h-5 pr-3 my-5">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPCHA_API_KEY}
                          onChange={onChange}
                          hl="en"
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className=" w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-transparent font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    Reset
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default NewPassword;
