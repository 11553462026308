import React from 'react';
import rightContent from 'assets/img/landing-page/right-content.png';
import { useNavigate } from 'react-router-dom';
import PiasIcon1 from 'assets/img/landing-page/piezas-1.png';
import PiasIcon2 from 'assets/img/landing-page/piezas-2.png';

const HeaderPage = ({ refH }) => {
  const navigate = useNavigate();
  return (
    <div className="bg-homepage mb-[20px] ">
      <div className="relative" style={{ width: '66.5px', height: '72px' }}>
        <div className="animate-floatingStartLeft hidden xl:block absolute top-[150px] left-[0] w-full h-full pointer-events-none ">
          <img
            src={PiasIcon1}
            alt="decorative"
            className="absolute top-[10%] left-[-1%] "
          />
        </div>
      </div>

      <div
        className="lg:mx-[100px] 2xl:mx-[150px] grid lg:grid-cols-2 grid-cols-1 gap-4 justify-between z-1 "
        ref={refH}
      >
        <div className="left-content py-[100px] px-[16px] wow animate__animated animate__bounceIn animate__delay-0.7s">
          <div className="font-[800] lg:text-[90px] text-[60px] tracking-tighter leading-[100px] opacity-75 pb-[24px]">
            Earn & get crypto in minutes
          </div>
          <div className="font-[100] text-[#777E90] leading-7">
            Our faucet website offers a simple and user-friendly platform for
            earning cryptocurrency by completing tasks such as surveys and
            games.
            {/* Easily claim your rewards today! */}
          </div>
          <button
            className="px-[24px] bg-base2 rounded-2xl mt-[24px] text-[#FFFFFF] h-[48px] cursor-pointer"
            onClick={() => navigate('/auth/login')}
          >
            Get started now
          </button>
        </div>

        <div className="right-content">
          <img src={rightContent} alt="" className="lg:scale-125 pt-[60px]" />
        </div>
        <div>
          <div className="animate-floatingStartLeft hidden xl:block absolute bottom-[200px] right-[0] w-[80px] h-[72px] pointer-events-none ">
            <img
              src={PiasIcon2}
              alt="decorative"
              className="absolute bottom-[15%] right-[0%] "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderPage;
