import React from 'react';
import CountUp from 'react-countup';

interface IPriceCountUpProps {
  endValue: number;
  prefix?: string;
  start?: number;
  decimals?: number;
  separator?: string;
  enableScrollSpy?: boolean;
  surfix?: string;
}
const PriceCountUp = ({
  endValue,
  prefix,
  start,
  decimals,
  separator,
  enableScrollSpy = false,
  surfix = '',
}: IPriceCountUpProps) => {
  return (
    <CountUp
      start={0}
      preserveValue
      delay={0}
      end={endValue}
      prefix={prefix}
      decimals={decimals}
      duration={1}
      separator={separator}
      enableScrollSpy={enableScrollSpy}
      redraw={true}
      suffix={surfix}
    >
      {({ countUpRef }) => {
        return <span className="" ref={countUpRef} />;
      }}
    </CountUp>
  );
};

export default PriceCountUp;
