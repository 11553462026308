import React, { useRef } from 'react';
import Logo from 'assets/img/bunnies/bunny7.png';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Earth from 'assets/img/landing-page/earth.png';
interface IHeader {
  goToFeature: () => void;
  goToInfo: () => void;
  goToContact: () => void;
  goToWork: () => void;
  goToHeader: () => void;
  goToDiscover: () => void;
}
const Header = ({
  goToFeature,
  goToInfo,
  goToContact,
  goToWork,
  goToHeader,
  goToDiscover,
}: IHeader) => {
  const navigate = useNavigate();
  return (
    <nav className="fixed top-0 z-50 left-0 w-full bg-white border-gray-200 px-2 sm:px-4 py-1 rounded dark:bg-gray-900 border-b-0 lg:border-b ">
      <div className="flex flex-wrap items-center justify-between mx-auto container mt- ">
        <a href="https://nokofaucet.com/" className="flex items-center">
          <img
            src={Earth}
            alt=""
            className="h-[45px] w-auto  relative bottom-1 "
          />

          <span className=" whitespace-nowrap dark:text-white text-[24px] font-[700] text-textMenu">
            Noko <span className="text-base5">Faucet</span>
          </span>
        </a>
        {/* <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button> */}
        <button
          onClick={() => navigate('/sign-up')}
          type="button"
          className="text-white bg-base2 hover:bg-base1 font-medium rounded-xl text-sm py-1.5 px-3 md:hidden"
        >
          Sign up
        </button>
        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className=" flex flex-col items-center p-2 mt-2 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-[16px] md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li onClick={goToHeader}>
              <p
                className="cursor-pointer block py-2 pl-3 pr-4 text-[#777E90] text-[14px] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-base4 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                aria-current="page"
              >
                Home
              </p>
            </li>
            <li onClick={goToDiscover}>
              <p className="cursor-pointer block py-2 pl-3 pr-4 text-[#777E90] text-[14px] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-base4 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                Discover
              </p>
            </li>

            <li onClick={goToFeature}>
              <p className="cursor-pointer block py-2 pl-3 pr-4 text-[#777E90] text-[14px] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-base4 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                Features
              </p>
            </li>
            {/* <li onClick={goToInfo}>
              <p className="cursor-pointer block py-2 pl-3 pr-4 text-[#777E90] text-[14px] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-base4 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                Info
              </p>
            </li>
            <li onClick={goToWork}>
              <p className="cursor-pointer block py-2 pl-3 pr-4 text-[#777E90] text-[14px] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-base4 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                How it work
              </p>
            </li> */}
            <li onClick={goToContact}>
              <p className="cursor-pointer block py-2 pl-3 pr-4 text-[#777E90] text-[14px] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-base4 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                Contact
              </p>
            </li>
            <li className="flex gap-2">
              <button
                onClick={() => navigate('/sign-up')}
                type="button"
                className="text-white bg-base2 hover:bg-base1 font-medium rounded-2xl text-sm py-1.5 px-3"
              >
                Sign up
              </button>
              <button
                type="button"
                className=" text-black font-medium rounded-2xl text-sm  border py-1.5 px-3"
                onClick={() => navigate('/auth/login')}
              >
                Login
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
