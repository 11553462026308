import * as React from 'react';
import { ErrorText } from '.';
import { Input } from './Input';
import { Label } from './Label';

type InputControlProps = {
  label: string;
  className?: string;
  error?: string;
  children?: React.ReactNode;
  isTextArea?: boolean;
  suffix?: React.ReactNode;
} & React.ComponentProps<'input'>;

export const InputControl = ({
  label,
  className,
  error,
  children,
  isTextArea,
  ...props
}: InputControlProps) => {
  return (
    <div className="relative w-full mb-3">
      <Label label={label} />
      {children ? children : <Input {...props} className={className} />}
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  );
};
