import React from 'react';
import {
  IfoIcon,
  ErrorIcon,
  CheckmarkCircleIcon,
  BlockIcon,
  WarningIcon,
} from 'assets/svg';
import { useMemo } from 'react';

const CustomAlert = ({ type, content }) => {
  const [getIconAlert, getBackground] = useMemo(() => {
    let icon = null as any;
    let bg = '';
    switch (type) {
      case 'Info':
        icon = <IfoIcon fill="white" />;
        bg = 'bg-base';
        break;
      case 'Error':
        icon = <ErrorIcon fill="white" />;
        bg = 'bg-base6';
        break;
      case 'Success':
        icon = <CheckmarkCircleIcon fill="white" />;
        bg = 'bg-base7';
        break;
      case 'Warning':
        icon = <WarningIcon fill="white" />;
        bg = 'bg-base8';
        break;
      default:
        icon = <BlockIcon fill="white" />;
        bg = 'bg-base';
    }
    return [icon, bg];
  }, [type]);
  return (
    <div className="flex h-full w-full items-center font-[Kanit] ">
      <div
        className={`left-alert w-1/6 h-full flex justify-center items-start ${getBackground}`}
      >
        {getIconAlert}
      </div>
      <div className="right-alert px-[16px]">
        <div className="type text-base11 font-semibold ">{type}</div>
        <div className="text-base11 text-[16px] font-normal ">{content}</div>
      </div>
    </div>
  );
};

export default CustomAlert;
