// import { MapGlDrawSchema } from 'pages/MapGlDraw/MapGlDraw.type';
// import { LoginFormSchema } from 'interfaces/login.type';
// import axios from '../axios';
import { sendPostRequest, sendDeleteRequest, sendPatchRequest, sendGetRequest } from '../axios';
// import { ENDPOINTS } from '../endpoints';


export const sendUpdateFaucetSetting = async (id: string, data: any) => {
    const res = await sendPatchRequest(`/faucet/update/${id}`, {
        ...data
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendCreateShortLink = async (data: any) => {
    const res = await sendPostRequest(`/shortlink/create-shortlink`, {
        ...data
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Create!');
    }
}

export const getShortLinkPagination = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string,
    sortBy?: string
) => {
    const res = await sendGetRequest(`/shortlink/getPagnigation?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&sortBy=${sortBy}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};

export const getShortLinkPaginationAdmin = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string,
    sortBy?: string
) => {
    const res = await sendGetRequest(`/shortlink/getPagnigation/admin?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&sortBy=${sortBy}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};

export const sendGetShortLinkById = async (id: string) => {
    const res = await sendGetRequest(`/shortlink/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
}

export const sendUpdateShortLink = async (id: string, data: any) => {
    const res = await sendPatchRequest(`/shortlink/update/${id}`, {
        ...data
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendDeleteShortLink = async (id: string) => {
    const res = await sendDeleteRequest(`/shortlink/delete/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendCreatePayment = async (data: any) => {
    const res = await sendPostRequest(`/payment/create-payment`, {
        ...data
    })
    if (res?.status === 200) {
        return res?.data;
    }
    else {
        throw new Error('Failed to Create!')
    }
}

export const getPaymentSettingsPagination = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string
) => {
    const res = await sendGetRequest(`/payment/getPagnigation?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};

export const sendUpdatePayment = async (id: string, data: any) => {
    const res = await sendPatchRequest(`/payment/update/${id}`, {
        ...data
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendGetPaymentById = async (id: string) => {
    const res = await sendGetRequest(`/payment/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
}

export const sendDeletePayment = async (id: string) => {
    const res = await sendDeleteRequest(`/payment/delete/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const getWithdrawPaginationAdmin = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string,
    condition?: string
) => {
    const res = await sendGetRequest(`/withdraw/pending?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&condition=${condition}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};

export const sendApproveWithdraw = async (id: string) => {
    const res = await sendPatchRequest(`/withdraw/approve/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendApproveWithdrawSelected = async (ids: Array<string>) => {
    const res = await sendPatchRequest(`/withdraw/approve-selected`,
        ids
    );
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendRefundWithdraw = async (id: string) => {
    const res = await sendPatchRequest(`/withdraw/refund/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendRejectWithdraw = async (id: string) => {
    const res = await sendPatchRequest(`/withdraw/reject/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendWithdraw = async (data: any) => {
    const res = await sendPostRequest(`/withdraw/send`, {
        ...data
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendWithdrawMultiple = async (arrayBody: Array<any>) => {
    const res = await sendPostRequest(`/withdraw/send-multiple`, arrayBody);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const getUsersPagination = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string,
    condition?: string
) => {
    const res = await sendGetRequest(`/user/getPagination?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&condition=${condition}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};

export const getUsersPaginationSameIp = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string,
    condition?: string
) => {
    const res = await sendGetRequest(`/user/getPagination/same-ip?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&condition=${condition}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};


export const sendBanUser = async (id: string) => {
    const res = await sendPatchRequest(`/user/ban/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendUnBanUser = async (id: string) => {
    const res = await sendPatchRequest(`/user/unBan/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendDeleteUser = async (id: string) => {
    const res = await sendDeleteRequest(`/user/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendCreateTask = async (data: any) => {
    const res = await sendPostRequest(`/tasks/create-task`, {
        ...data
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Create!');
    }
}

export const getTasksPagination = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string,
    sortBy?: string
) => {
    const res = await sendGetRequest(`/tasks/getPagnigation?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&sortBy=${sortBy}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};

export const sendUpdateTask = async (id: string, data: any) => {
    const res = await sendPatchRequest(`/tasks/update/${id}`, {
        ...data
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendDeleteTask = async (id: string) => {
    const res = await sendDeleteRequest(`/tasks/delete/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendGetTaskById = async (id: string) => {
    const res = await sendGetRequest(`/tasks/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
}

export const sendCreateBonus = async (data: any) => {
    const res = await sendPostRequest(`/bonus/create-bonus`, {
        ...data
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Create!');
    }
}

export const sendGetAllBonus = async () => {
    const res = await sendGetRequest(`/bonus`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}


export const sendGetBonusById = async (id: string) => {
    const res = await sendGetRequest(`/bonus/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
}

export const sendUpdateBonus = async (id: string, data: any) => {
    const res = await sendPatchRequest(`/bonus/update/${id}`, {
        ...data
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendDeleteBonus = async (id: string) => {
    const res = await sendDeleteRequest(`/bonus/delete/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendUpdateGeneralSetting = async (id: string, data: any) => {
    const res = await sendPatchRequest(`/general-setting/update/${id}`, {
        ...data
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendGetAllUser = async () => {
    const res = await sendGetRequest('/user/getAll');
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
}

export const sendGetUserById = async (id: string) => {
    const res = await sendGetRequest(`/user/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
}

export const sendGetHistoryByUserId = async (id: string) => {
    const res = await sendGetRequest(`/history/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
}

export const sendUpdateUser = async (id: string, data: any) => {
    const res = await sendPatchRequest(`/user/edit/${id}`, {
        ...data
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendUpdateHistory = async (id: string, data: any) => {
    const res = await sendPatchRequest(`/history/edit/${id}`, {
        ...data
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const getCampaignPaginationAdmin = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string,
    condition?: string
) => {
    const res = await sendGetRequest(`ptc/getPaginationAdmin?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&condition=${condition}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};

export const sendApprovePtc = async (id: string) => {
    const res = await sendPatchRequest(`/ptc/approve/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendRejectPtc = async (id: string) => {
    const res = await sendPatchRequest(`/ptc/reject/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}


export const sendRefundPtc = async (id: string) => {
    const res = await sendPatchRequest(`/ptc/refund/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendApproveOfferwall = async (id: string) => {
    const res = await sendPatchRequest(`/offerwall/approve/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendApproveOfferwallSelected = async (ids: Array<string>) => {
    const res = await sendPatchRequest(`/offerwall/approve-selected`,
        ids
    );
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendRejectOfferwall = async (id: string) => {
    const res = await sendPatchRequest(`/offerwall/reject/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendApprovePtcSelected = async (ids: Array<string>) => {
    const res = await sendPatchRequest(`/ptc/approve-selected-ptc`,
        ids
    );
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendDeletePTCByAdmin = async (id: string) => {
    const res = await sendDeleteRequest(`/ptc/delete-by-admin/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendGetCapchaByAdmin = async () => {
    const res = await sendGetRequest(`capcha/image-capcha/admin`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
}

export const sendGetAnitbotByAdmin = async () => {
    const res = await sendGetRequest(`antibot/get-antibot-admin`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
}


export const sendDeleteCapchaByAdmin = async (id: string) => {
    const res = await sendDeleteRequest(`capcha/delete-capcha-admin/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const getRankingPaymentPaginationAdmin = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string,
    condition?: string
) => {
    const res = await sendGetRequest(`/ranking-history/admin?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&condition=${condition}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};

export const sendApproveRankingPaymentSelected = async (ids: Array<string>) => {
    const res = await sendPatchRequest(`/ranking-history/approve-selected`,
        ids
    );
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendRejectRankingPayment = async (id: string) => {
    const res = await sendPatchRequest(`/ranking-history/reject/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}


export const sendPrize = async (data: any) => {
    const res = await sendPostRequest(`/ranking-history/send`, {
        ...data
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}


export const sendPrizeMultiple = async (arrayBody: Array<any>) => {
    const res = await sendPostRequest(`/ranking-history/send-multiple`, arrayBody);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendGetShortLinkDetailsByAdmin = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string,
    condition?: string) => {
    const res = await sendGetRequest(`/shortlink-tmp?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&condition=${condition}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
}

export const sendGetSortlinkHistoryTop = async () => {
    const res = await sendGetRequest('/history/shortlinktop');
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
}













