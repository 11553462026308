import React, { useCallback, useEffect, useRef } from 'react';
import Feature from './feature/Feature';
import Footer from './footer';
import Header from './Header';
import HeaderPage from './HeaderPage';
import WOW from 'wowjs';
import CryptoPrice from './CryptoPrice';
import useCurrencies from 'hooks/currencies.hook';
import HowItWork from './HowItWork';
import ContactUs from './ContactUs';
import ReactSlick from 'components/common/ReactSlick';
import BunnyFly2 from 'assets/slick/bunny_fly2.png';
import JoinCommunity from './JoinCommunity';
import DiscoverPage from './DiscoverPage';

const LandingPage = () => {
  const ref1 = useRef(HTMLDivElement as any);
  const ref2 = useRef(HTMLDivElement as any);
  const ref3 = useRef(HTMLDivElement as any);
  const ref4 = useRef(HTMLDivElement as any);
  const refH = useRef(HTMLDivElement as any);
  const refDiscover = useRef(HTMLDivElement as any);

  useEffect(() => {
    const wow = new WOW.WOW({
      mobile: false,
      live: true, // bật chế độ live để hiệu ứng chạy mỗi khi người dùng cuộn chuột
    });
    wow.init();
  }, []);

  const { currencies, refetch } = useCurrencies();

  useEffect(() => {
    refetch();
  }, []);

  const goToFeature = useCallback(() => {
    if (ref1 && ref1.current /* + other conditions */) {
      ref1.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, []);
  const goToInfo = useCallback(() => {
    if (ref2 && ref2.current /* + other conditions */) {
      ref2.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);
  const goToWork = useCallback(() => {
    if (ref3 && ref3.current /* + other conditions */) {
      ref3.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);
  const goToContact = useCallback(() => {
    if (ref4 && ref4.current /* + other conditions */) {
      ref4.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  const goToHeader = useCallback(() => {
    if (refH && refH.current /* + other conditions */) {
      refH.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  const goToDiscover = useCallback(() => {
    if (refDiscover && refDiscover.current /* + other conditions */) {
      refDiscover.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);
  return (
    <div className="bg-[#FCFCFD]">
      <Header
        goToFeature={goToFeature}
        goToInfo={goToInfo}
        goToWork={goToWork}
        goToContact={goToContact}
        goToHeader={goToHeader}
        goToDiscover={goToDiscover}
      />
      <HeaderPage refH={refH} />

      {/* <div className="z-50 bg-white pb-[30px]">
        <ReactSlick />
      </div> */}

      <CryptoPrice dataCoin={currencies} />
      <DiscoverPage refDiscover={refDiscover} />
      <Feature ref1={ref1} />

      {/* <InfoFaucet ref2={ref2} /> */}
      {/* <HowItWork ref3={ref3} /> */}
      <ContactUs ref4={ref4} />

      <Footer />
    </div>
  );
};

export default LandingPage;
