import React from 'react';

interface IProgressProp {
  fill?: string;
  percent?: number;
}

const ProgressCustom = ({ fill, percent }: IProgressProp) => {
  return (
    <div className="relative w-full h-[8px] bg-gray-300 rounded-full overflow-hidden ">
      <div
        className={`absolute top-0 left-0 h-full  rounded-full ${
          fill ? fill : 'bg-base'
        }`}
        style={{ width: `${percent}%` }}
      ></div>
    </div>
  );
};

export default ProgressCustom;
