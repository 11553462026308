// import { MapGlDrawSchema } from 'pages/MapGlDraw/MapGlDraw.type';
// import { LoginFormSchema } from 'interfaces/login.type';
// import API from '../axios';
// import { ENDPOINTS } from '../endpoints';
import { sendPostRequest, sendDeleteRequest, sendPatchRequest, sendGetRequest } from '../axios';


export const sendGetCurrentUserRequest = async () => {
    const res = await sendGetRequest(`/auth/me`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendClaimReward = async (payload: any) => {
    const res = await sendPostRequest(`/user/claim`, {
        ...payload
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error(res?.data?.message);
    }
}

export const sendGetFaucetSettings = async () => {
    const res = await sendGetRequest(`/faucet`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

// export const sendPostViewSortLink = async (shortLinkId: string) => {
//     const res = await sendPostRequest(`/shortlink/view/${shortLinkId}`);
//     if (res?.status === 200) {
//         return res?.data;
//     } else {
//         throw new Error('Failed to fetch!');
//     }
// }

export const sendPostVerifySortLink = async (data: any) => {
    const res = await sendPostRequest(`/shortlink/verify-shortlink`, {
        ...data
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendGetPools = async () => {
    const res = await sendGetRequest(`/pools`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendGetAllPayment = async () => {
    const res = await sendGetRequest(`/payment`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendGetAllCurrencies = async () => {
    const res = await sendGetRequest(`/currency`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendWithDraw = async (data: any) => {
    const res = await sendPostRequest(`/withdraw`, data);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error(res?.data?.message);
    }
}

export const getWithdrawPagination = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string,
    condition?: string
) => {
    const res = await sendGetRequest(`/withdraw/history?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&condition=${condition}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};

export const sendActiveUser = async (token: string) => {
    const res = await sendGetRequest(`/user/active/${token}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const getCurrentHistory = async () => {
    const res = await sendGetRequest('/history');
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendClaimTaskReward = async (id: string) => {
    const res = await sendPatchRequest(`/tasks/claim/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendForgotPassword = async (email: string) => {
    const res = await sendPatchRequest(`/forgot-password`, email);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendGetCheckinInfo = async () => {
    const res = await sendGetRequest(`/daily-bonus/check-in-log`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendCheckInBonus = async () => {
    const res = await sendPostRequest(`/daily-bonus/checkin`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendGetGeneralSettings = async () => {
    const res = await sendGetRequest(`/general-setting`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const getCommissionPagination = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string,
    sortBy?: string
) => {
    const res = await sendGetRequest(`/user/getCommission?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&sortBy=${sortBy}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};


export const getDepositPagination = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string,
) => {
    const res = await sendGetRequest(`/deposit/pagination?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};

export const sendCreatePTC = async (data: any) => {
    const res = await sendPostRequest(`/ptc/create`, data);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to withdraw!');
    }
}

export const getCampaignPagination = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string,
    condition?: string
) => {
    const res = await sendGetRequest(`ptc?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&condition=${condition}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};

export const getCampaignPaginationForUser = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string,
    condition?: string
) => {
    const res = await sendGetRequest(`ptc/getPaginationUser?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&condition=${condition}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};

export const getCampaignForUser = async () => {
    const res = await sendGetRequest('ptc/ptc-for-user');
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};

export const sendClaimPtc = async (id: string) => {
    const res = await sendPostRequest(`/ptc/claim`, { id });

    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendUpdatePtcOwn = async (id: string, data: any) => {
    const res = await sendPatchRequest(`/ptc/update/${id}`, {
        ...data
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendGetPtcOwnById = async (id: string) => {
    const res = await sendGetRequest(`/ptc/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
}

export const sendAddMoreViewPtc = async (id: string, data: any) => {
    const res = await sendPatchRequest(`/ptc/adview/${id}`, {
        ...data
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const sendDeletePtcOwn = async (id: string) => {
    const res = await sendDeleteRequest(`/ptc/delete/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const getOffetwallHistory = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string,
    condition?: string
) => {
    const res = await sendGetRequest(`/offerwall/history?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&condition=${condition}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};

export const getOffetwallHistoryAdmin = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string,
    condition?: string
) => {
    const res = await sendGetRequest(`/offerwall/history/admin?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&condition=${condition}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};

export const sendPostExchangeTokensToDeposit = async (data: any) => {
    const res = await sendPostRequest(`/payment/exchange`, {
        ...data
    });
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Exchange failed!');
    }
}

export const sendGetRankingUser = async () => {
    const res = await sendGetRequest(`/ranking`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}

export const getRankingPaymentPagination = async (
    page: number,
    perPage: number,
    keyword: string,
    sort?: string,
    condition?: string
) => {
    const res = await sendGetRequest(`/ranking-history/getpagination?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=${sort}&condition=${condition}&paginationVersion=2`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to Get!');
    }
};



export const sendGenerateUrlShortLink = async (id: any) => {
    const res = await sendGetRequest(`/shortlink/generate/${id}`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('Failed to gen!');
    }
}

export const sendResendEmail = async () => {
    const res = await sendPostRequest(`/user/resend-email`);
    if (res?.status === 200) {
        return res?.data;
    } else {
        throw new Error('failed!');
    }
}

export const sendGetInitInfo = async () => {
    const res = await sendGetRequest(`/general-setting/getInitInfo`);
    if (res.status === 200) {
        return res.data;
    } else {
        throw new Error('Failed to fetch!');
    }
}









