import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface IUserSlice {
  clientId: string | null;
  history: any;
}

const initialState: IUserSlice = {
  clientId: null,
  history: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeUserSelected: (state, action: PayloadAction<any>) => {
      state.clientId = action.payload;
    },
    setHistory: (state, action: PayloadAction<any>) => {
      state.history = action.payload;
    }
  },
});

export const { changeUserSelected, setHistory } = userSlice.actions;

export const userSelector = (state: RootState) => state.user;

export default userSlice.reducer;
