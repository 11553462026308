import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { isValidToken } from 'utils/auth';
import useRole from 'hooks/role.hook';
import Spinner from 'components/common/Spinner';
import useProfile from 'hooks/useProfile';
import { StatusEnum } from 'interfaces/login.type';

const RequireAuth: React.FC<any> = ({ children }) => {
  // const isAuth = isValidToken();

  const { role, isLoading } = useRole();

  if (isLoading) {
    return <Spinner />;
  }

  return role ? (
    role === 'admin' ? (
      <>{children}</>
    ) : (
      <Navigate to="/user" replace />
    )
  ) : (
    <Navigate to="/auth/login" replace />
  );
};

export default RequireAuth;
