import Axios from 'axios';
import { TOKEN_KEY } from '../models/auth';

const API_URL =
  process.env.REACT_APP_API || 'http://localhost:3001/api';
const axios = Axios.create({
  baseURL: API_URL,
  timeout: 30000,
});
const setupInterceptors = (navigate: any) => {
  axios.interceptors.request.use(
    function (config) {
      //inject token here if needed
      if (typeof window !== 'undefined') {
        const accessToken = localStorage.getItem(TOKEN_KEY);
        Object.assign(config.headers, {
          'Authorization': `Bearer ${accessToken}`,
        });
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    (error) => {
      if (error?.response) {
        if (error?.response?.data?.message?.type === 'locked' && error?.response?.status === 418) {
          localStorage.setItem('locked_until', error?.response?.data?.message?.lock_until);
          navigate('locked');
        }
        if (error?.response?.data?.message?.type === 'banned' && error?.response?.status === 418) {
          navigate('banned');
        }
      }
      return error?.response
      // return Promise.reject(error);
    },

  );
}

export default setupInterceptors;

export const sendGetRequest = (url: string, params?: any) => axios.get(url, { params });
export const sendPatchRequest = (url: string, params?: any) => axios.patch(url, params);
export const sendPostRequest = (url: string, params?: any, headers?: any) => axios.post(url, params, headers);
export const sendDeleteRequest = (url: string, params?: any) => axios.delete(url, params);
