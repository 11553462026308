import React from 'react';
import discoverBg from 'assets/img/landing-page/backgroundDiscover.png';
import ScrollDiv from '../ScrollDiv';

const DiscoverPage = ({ refDiscover }) => {
  return (
    <div
      className=" font-[900] text-center lg:px-[250px] pb-[100px]"
      ref={refDiscover}
    >
      <ScrollDiv
        pageYOffset={500}
        animate="animate__fadeInUp animate__animated animate__delay-0.7s h-auto"
      >
        <p className="lg:text-[90px] text-[60px] lg:py-[142px] pt-[350px] pb-[100px] leading-[100%] tracking-tighter">
          Discover our mad dream of making the world play!
        </p>
      </ScrollDiv>

      <ScrollDiv
        pageYOffset={950}
        animate="animate__fadeInUp animate__animated animate__delay-0.7s h-auto"
      >
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-2">
          <div className="relative lg:h-[356px] lg:w-[565px] w-[100%] py-[70px] px-[80px] rounded-[24px] text-left lg:col-span-1 col-span-2">
            <div className="absolute inset-0 bg-bgDiscover1 from-customGradientColor to-transparent opacity-10 rounded-[24px]"></div>
            <div className="text-[54px] leading-[50px] bg-bgTextDiscover1 bg-clip-text text-transparent">
              10K+ <br /> Members
            </div>
            <p className="pt-[40px] font-normal">
              We are at the beginning of our mission to deliver fun and over
              10,000 players have already joined us
            </p>
          </div>
          <div className="relative lg:h-[356px] lg:w-[565px] py-[70px] px-[80px] rounded-[24px] text-left lg:col-span-1 col-span-2">
            <div className="absolute inset-0 bg-bgDiscover2 from-customGradientColor to-transparent opacity-10 rounded-[24px]"></div>
            <div className="text-[54px] leading-[50px] bg-bgTextDiscover2 bg-clip-text text-transparent">
              15+ games <br /> live
            </div>
            <p className="pt-[40px] font-normal">
              Creating amazing faucet games is our DNA! Come and discover our
              latest games and future releases.
            </p>
          </div>
        </div>
      </ScrollDiv>
      {/* <ScrollDiv
        pageYOffset={1200}
        animate="animate__fadeInUp animate__animated animate__delay-0.7s h-auto"
      > */}
      <div className="text-center animate__bounceIn wow animate__delay-5s">
        <img src={discoverBg} alt="" />
      </div>
      {/* </ScrollDiv> */}
    </div>
  );
};

export default DiscoverPage;
