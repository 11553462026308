import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'animate.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from 'store/store';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';
// import { AdBlockDetectedWrapper } from 'adblock-detect-react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <ConfigProvider
    theme={{
      token: {
        fontFamily: 'Kanit',
        colorText: '#280D5F',
        fontWeightStrong: 300,
      },
    }}
  >
    <ReduxProvider store={store}>
      <Helmet>
        <title>Home | Noko Faucet - The high paying faucet website!</title>
        <meta name="Noko Faucet" content="High payment faucet" />
        <meta name="theme-color" content="#ffff" />
      </Helmet>
      <App />
    </ReduxProvider>
  </ConfigProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
