import React, { useMemo } from 'react';

const CoinzillaCommon = ({ type }) => {
  const CoinzillaAds = useMemo(() => {
    switch (type) {
      case '728':
        return (
          <iframe
            scrolling="no"
            src="https://nokofaucet.com/cz/728"
            className="w-[728px] h-[90px]"
          ></iframe>
        );
      case '300':
        return (
          <iframe
            scrolling="no"
            src="https://nokofaucet.com/cz/300"
            className="w-[300px] h-[250px]"
          ></iframe>
        );
    }
  }, [type]);
  return <div>{CoinzillaAds}</div>;
};

export default CoinzillaCommon;
