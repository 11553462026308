import { QUERY_KEY } from 'interfaces/constants';
import { sendGetCurrentUserRequest } from 'network/services/auth';
import { sendGetInitInfo } from 'network/services/user';
import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { isValidToken } from 'utils/auth';

const useInitInfo = () => {
  const isAuth = isValidToken();
  const {
    data: initInfoData,
    isLoading,
    refetch,
  } = useQuery([QUERY_KEY.GET_INIT_INFO], () => sendGetInitInfo(), {
    // keepPreviousData: false,
    retry: false,
    enabled: !!isAuth,
  });
  const initInfo = useMemo(() => {
    return initInfoData && initInfoData;
  }, [initInfoData]);

  return { initInfo, isLoading, refetch };
};

export default useInitInfo;
