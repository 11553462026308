import { QUERY_KEY } from 'interfaces/constants';
import { sendGetCurrentUserRequest } from 'network/services/auth';
import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { isValidToken } from 'utils/auth';

const useProfile = () => {
  const isAuth = isValidToken();
  const {
    data: currentUser,
    isLoading,
    refetch,
  } = useQuery(
    [QUERY_KEY.GET_CURRENT_USER],
    () => sendGetCurrentUserRequest(),
    {
      // keepPreviousData: false,
      retry: false,
      enabled: !!isAuth,
    }
  );
  const profile = useMemo(() => {
    return currentUser && currentUser;
  }, [currentUser]);

  return { profile, isLoading, refetch };
};

export default useProfile;
