import React from 'react';
import Svg from '../../Svg';
import { SvgProps } from '../../types';

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 220 220" {...props}>
      <g clip-path="url(#clip0_108_5)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M109.57 0C131.326 0 152.593 6.45139 170.683 18.5383C188.772 30.6253 202.871 47.805 211.197 67.9048C219.522 88.0047 221.701 110.122 217.456 131.46C213.212 152.798 202.736 172.398 187.352 187.782C171.968 203.166 152.368 213.642 131.03 217.886C109.692 222.131 87.5748 219.952 67.4749 211.627C47.375 203.301 30.1954 189.202 18.1084 171.113C6.02146 153.023 -0.429932 131.756 -0.429932 110C-0.429932 80.8262 11.1593 52.8473 31.7883 32.2183C52.4173 11.5893 80.3963 0 109.57 0V0Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M109.57 23.6201L138.51 66.8601L167.45 110.11L109.57 139.27L51.6899 110L80.6299 66.7501L109.57 23.6201ZM109.57 150.92L54.8999 121.75L109.57 196.38L164.24 121.75L109.57 150.92Z"
          fill="#343434"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M109.57 23.6201L138.51 66.7501L167.45 110L109.57 88.1501V23.6201Z"
          fill="#343434"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M109.57 150.92L164.35 121.75L109.57 196.49V150.92Z"
          fill="#313131"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M109.57 88.1499L167.45 110L109.57 139.16V88.1499Z"
          fill="#151515"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M109.57 23.6201L80.6299 66.7501L51.6899 110L109.57 88.1501V23.6201Z"
          fill="#8C8C8C"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M109.57 150.92L54.8999 121.75L109.57 196.49V150.92Z"
          fill="#8A8A8A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M109.57 88.1499L51.6899 110L109.57 139.16V88.1499Z"
          fill="#353535"
        />
      </g>
      <defs>
        <clipPath id="clip0_108_5">
          <rect width="220" height="220" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
