import React, { Suspense, useMemo, useState, useEffect } from 'react';

import { SideBar } from 'components/sidebar';
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import TopHeader from 'components/top-header';
import { useQueryClient } from 'react-query';
import { QUERY_KEY } from 'interfaces/constants';
import useRole from 'hooks/role.hook';
import Spinner from 'components/common/Spinner';
import Scrollbars from 'react-custom-scrollbars';
import { useMediaQuery } from 'react-responsive';

const MainLayoutCustom = () => {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 640px)' });
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isMobileScreen) {
      setIsOpen(false);
    }
  }, []);

  return (
    <Scrollbars style={{ height: 'calc(100vh - 10px)' }} autoHide>
      <div className="bg-2 mt-[70px] min-h-screen z-10">
        <TopHeader onToggle={onToggle} isOpen={isOpen} />
        <SideBar isOpen={isOpen} onToggle={onToggle} />
        <div
          className={`transition-all duration-500 ease-out transform translate-x-0 translate-y-0 translate-z-0 ${
            isOpen ? 'ml-64 ' : 'lg:ml-16 '
          }`}
        >
          <div className="flex-1 px-[24px] pt-[16px] relative">
            <Suspense fallback={<Spinner />}>
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
    </Scrollbars>
  );
};

export default MainLayoutCustom;
