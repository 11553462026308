const ASSET_CDN = 'https://assets.pancakeswap.finance';
const BgWrapper = ({ children }) => (
  <div className="absolute w-full h-full top-[50px] left-0 z-[10]">
    {children}
  </div>
);

const LeftBottomBox = () => {
  return (
    <div className="hidden absolute w-[391px] h-[620px] top-[150px] left-[10px] lg:block">
      <img
        className="absolute w-[180px] top-[357px] left-[29px] z-[20]"
        src={`${ASSET_CDN}/web/landing/socials/1.png`}
        alt="discord"
      />
      <img
        className="absolute w-[230px] top-0 left-[30px] z-[30]"
        src={`${ASSET_CDN}/web/landing/socials/2.png`}
        alt="twitter"
      />
      <img
        className="absolute w-[220px] top-[94px] left-[120px] z-[20]"
        src={`${ASSET_CDN}/web/landing/socials/3.png`}
        alt="telegram"
      />
      <img
        className="absolute w-[240px] top-[244px] left-[78px] z-[10]"
        src={`${ASSET_CDN}/web/landing/socials/4.png`}
        alt="instagram"
      />
    </div>
  );
};

const RightTopBox = () => {
  return (
    <div className="absolute w-[320px] h-[520px] left-[calc(50%-196px+30px)] top-[-206px] z-[30] transform scale-[0.8] origin-center sm:scale-[1] sm:left-[calc(50%-196px+60px)] sm:top-[-186px] lg:left-[calc(50%-196px+290px)] lg:top-[-140px] 2xl:left-[calc(100%-326px)] 2xl:top-[-180px]">
      <img
        className="absolute w-[150px] top-[300px] left-[100px] z-[3]"
        src={`${ASSET_CDN}/web/landing/community-items/1.png`}
        alt="pink-bubble"
      />
      <img
        className="absolute w-[250px] top-[200px] left-[-50px] z-[1]"
        src={`${ASSET_CDN}/web/landing/community-items/2.png`}
        alt="ring"
      />
      <img
        className="absolute w-[80px] top-[120px] z-[3]"
        src={`${ASSET_CDN}/web/landing/community-items/3.png`}
        alt="heart"
      />
      <img
        className="absolute w-[250px] top-[100px] left-[50px] z-[3]"
        src={`${ASSET_CDN}/web/landing/community-items/4.png`}
        alt="speaker"
      />
      <img
        className="absolute w-[120px] top-[120px] left-[200px] z-[2]"
        src={`${ASSET_CDN}/web/landing/community-items/5.png`}
        alt="yellow-bubble"
      />
    </div>
  );
};

const JoinCommunity = () => (
  <BgWrapper>
    <RightTopBox />
    <LeftBottomBox />
  </BgWrapper>
);
export default JoinCommunity;
