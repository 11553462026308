import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import avatar from '../../assets/img/avatar.png';
import {
  BunnyPlaceholderIcon,
  CogIcon,
  GroupsIcon,
  LogoIcon,
  LogoRoundIcon,
  LogoutIcon,
  NoProfileAvatarIcon,
} from 'assets/svg';
import { useQueryClient } from 'react-query';
import { QUERY_KEY } from 'interfaces/constants';

function AvatarDropdown() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleSignOut = () => {
    navigate('/auth/login');
    localStorage.removeItem('token');
    localStorage.removeItem('isAuthenticated');
    setIsOpen(false);
    queryClient.setQueryData([QUERY_KEY.GET_ROLE], null);
  };

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className="flex items-center bg-white text-textMenu hover:bg-gray-200 focus:outline-none focus:shadow-outline rounded-full"
      >
        {/* <img
          className="w-10 h-10 rounded-full hover:scale-110 duration-100"
          src={avatar}
          alt="Rounded avatar"
        ></img> */}
        <BunnyPlaceholderIcon className="w-8 h-8  hover:scale-110 duration-100" />
        {/* <div className="hover:scale-150 duration-100">
          <LogoIcon />
        </div> */}
      </button>
      {isOpen && (
        <div
          ref={dropdownRef}
          className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-lg shadow-xl cursor-pointer text-textMenu"
        >
          <Link
            to="/dashboard"
            className="flex items-center px-4 py-2 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
          >
            <div className="pr-2">
              <GroupsIcon />
            </div>
            Profile
          </Link>
          <Link
            to="/user/account-settings"
            className="flex items-center px-4 py-2 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
          >
            <div className="pr-2">
              <CogIcon />
            </div>
            Account
          </Link>
          <div
            onClick={handleSignOut}
            className="flex items-center px-4 py-2 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
          >
            <div className="pr-2">
              <LogoutIcon />
            </div>
            Logout
          </div>
        </div>
      )}
    </div>
  );
}

export default AvatarDropdown;
