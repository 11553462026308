import BigNumber from "bignumber.js";

export const nFormatter = (
    number: string,
    digits = 2,
    roundingMode?: BigNumber.RoundingMode
) => {
    const SI = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
        { value: 1e12, symbol: "T" },
    ];
    if (new BigNumber(number).isNaN()) return "0";

    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const num = parseFloat(number);

    let i;
    for (i = SI.length - 1; i > 0; i--) {
        if (num >= SI[i].value) {
            break;
        }
    }
    const roundingModeCombined = roundingMode || BigNumber.ROUND_FLOOR;

    const minimumNumber = BigNumber(1).div(`1e${digits}`).toNumber();

    if (Number(number) > 0 && new BigNumber(number).lt(minimumNumber)) {
        return "~" + minimumNumber;
    }

    return (
        new BigNumber(num)
            .div(SI[i].value)
            .toFixed(digits, roundingModeCombined)
            .toString()
            .replace(rx, "$1") + SI[i].symbol
    );
};