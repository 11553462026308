import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface IAntibotSlice {
    keys: string | null;
}

const initialState: IAntibotSlice = {
    keys: null
};

export const antibotSlice = createSlice({
    name: 'antibot',
    initialState,
    reducers: {
        setAntibot: (state, action: PayloadAction<any>) => {
            state.keys = action.payload[0]
        },
    },
});

export const { setAntibot } = antibotSlice.actions;

export const antibotSelector = (state: RootState) => state.antibot;

export default antibotSlice.reducer;
