import Spinner from 'components/common/Spinner';
import useRole from 'hooks/role.hook';
import { Suspense, useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { isValidToken } from 'utils/auth';
import { Navigate } from 'react-router-dom';
const AuthLogin = () => {
  // const isAuth = isValidToken();
  const { role, isVerified, isLoading, refetchRole, isError } = useRole();
  const navigate = useNavigate();
  const params = useLocation();

  // useEffect(() => {
  //   if (role) {
  //     if (role === 'admin') {
  //       params.pathname !== '/admin' && navigate('/admin');
  //     } else if (params.pathname !== '/user') {
  //       navigate('/user');
  //     }
  //   }
  // }, [role]);
  if (isError) return <></>;

  if (isLoading) return <Spinner />;

  return (
    <>
      <div className="rounded-lg dark:border-gray-700 ">
        <div className="flex-1">
          {/* <Suspense fallback={<Spinner />}> */}
          {role ? (
            role === 'admin' ? (
              <Navigate to="/admin" replace />
            ) : (
              <Navigate to="/user" replace />
            )
          ) : (
            <Outlet />
          )}

          {/* </Suspense> */}
        </div>
      </div>
    </>
  );
};

export default AuthLogin;
