import React from 'react';
import Svg from '../../Svg';
import { SvgProps } from '../../types';

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 220 220" {...props}>
      <g clip-path="url(#clip0_108_139)">
        <path
          d="M110 220C170.751 220 220 170.751 220 110C220 49.2487 170.751 0 110 0C49.2487 0 0 49.2487 0 110C0 170.751 49.2487 220 110 220Z"
          fill="#C3A634"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M91.08 100.444H120.739V116.16H91.08V149.284H109.787C117.191 149.284 123.248 148.287 127.964 146.279C132.681 144.279 136.379 141.508 139.068 137.961C141.817 134.272 143.689 130.004 144.54 125.483C145.533 120.382 146.017 115.196 145.984 110C146.017 104.804 145.533 99.6177 144.54 94.5175C143.689 89.9958 141.818 85.728 139.068 82.0394C136.379 78.4919 132.674 75.7212 127.964 73.7206C123.248 71.7131 117.191 70.7163 109.787 70.7163H91.08V100.451V100.444ZM72.0638 116.16H61.875V100.451H72.0638V55H117.088C125.407 55 132.605 56.4437 138.689 59.3106C144.774 62.1912 149.737 66.1169 153.594 71.1012C157.444 76.0788 160.311 81.9088 162.188 88.5844C164.065 95.26 165 102.403 165 110C165.047 117.234 164.099 124.44 162.181 131.416C160.311 138.084 157.444 143.921 153.588 148.899C149.738 153.883 144.774 157.809 138.689 160.689C132.605 163.563 125.4 165 117.088 165H72.0638V116.16Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_108_139">
          <rect width="220" height="220" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
